const inputArgs = [
    {
      key: 'name',
      name: '活动名称',
      type: 'text'
    },
    {
      key: 'prize_name',
      name: '奖品名称',
      type: 'text'
    },
    {
      key: 'put_begin_time_format',
      name: '投币开始时间',
      type: 'time'
    },
    {
      key: 'put_end_time_format',
      name: '投币结束时间',
      type: 'time'
    },
    {
      key: 'max_coin_per_user',
      name: '每个用户最大投币数',
      type: 'text'
    },
    {
      key: 'max_coin_all',
      name: '整个活动最大投币数',
      type: 'text'
    },
    {
      key: 'entry_pic',
      name: '个人中心入口图片',
      type: 'image',
      width: 335,
      height: 66
    },
    {
      key: 'intro_pic',
      name: '奖品介绍图片',
      type: 'image',
      width: 140,
      height: 100
    },
    {
      key: 'rule_pic',
      name: '规则图片',
      type: 'image',
      width: 140,
      height: 100
    },
    {
      key: 'bg_intro_pic',
      name: '介绍页背景图',
      type: 'image',
      width: 140,

    },
    {
      key: 'bg_put_pic',
      name: '投币页背景图',
      type: 'image',
      width: 140,

    },
    {
      key: 'bg_result_pic',
      name: '开奖页背景图',
      type: 'image',
      width: 140,

    }
  ]

const themeArgs = [
    {
        key: 'primary_color',
        defaultValue: '#BF1B1B',
        name: '主题色',
        type: 'text'
    }, {
        key: 'default_color',
        defaultValue: '#111111',
        name: '默认文字色',
        type: 'text'
    }, {
        key: 'background_color',
        defaultValue: '',
        name: '页面背景色',
        type: 'text'
    }, {
        key: 'button_background_color',
        defaultValue: '#BF1B1B',
        name: '按钮背景色',
        type: 'text'
    }, {
        key: 'button_color',
        defaultValue: '#FEFEFE',
        name: '按钮文字色',
        type: 'text'
    },  {
      key: 'rule_drawer_bg_color',
      defaultValue: '#BF1B1B',
      name: '抽奖规则弹层颜色',
      type: 'text'
    }, {
      key: 'prize_drawer_bg_color',
      defaultValue: '#BF1B1B',
      name: '奖品介绍弹层颜色',
      type: 'text'
    }, {
        key: 'icon_button_color',
        defaultValue: '#BF1B1B',
        name: '加减号icon颜色',
        type: 'text'
    }, {
        key: 'button_disable_background_color',
        defaultValue: '#999999',
        name: '不可点按钮背景色',
        type: 'text'
    }, {
        key: 'button_disable_color',
        defaultValue: '#FEFEFE',
        name: '不可点按钮文字色',
        type: 'text'
    }, {
        key: 'input_border_color',
        defaultValue: '#EFEFEF',
        name: '投币数字框外框颜色',
        type: 'text'
    }, {
        key: 'input_background_color',
        defaultValue: '#EFEFEF',
        name: '投币数字框背景色',
        type: 'text'
    },{
        key: 'lmb_border_color',
        defaultValue: '#EFEFEF',
        name: '蓝莓币数字外框颜色',
        type: 'text'
    }, {
        key: 'lmb_background_color',
        defaultValue: '#EFEFEF',
        name: '蓝莓币数字背景色',
        type: 'text'
    }, {
        key: 'number_color',
        defaultValue: '#BF1B1B',
        name: '数字颜色',
        type: 'text'
    }, {
        key: 'ticket_win_color',
        defaultValue: '#BF1B1B',
        name: '获奖奖券颜色',
        type: 'text'
    }, {
        key: 'ticket_win_bold',
        defaultValue: true,
        name: '获奖奖券是否加粗',
        type: 'boolean'
    }, {
        key: 'lmb_bg_pic',
        defaultValue: 'https://pic.lmtest.cn/activities/FtCcDMNByu_Y50fHDMKfOxxpidxd',
        name: '蓝莓币背景图',
        type: 'image',
        width: 343,
        height: 80
    }, {
        key: 'entry_button_pic',
        defaultValue: '',
        name: '开始投币按钮图',
        type: 'image',
        width: 243,
        height: 61
    }
  ]

export { inputArgs, themeArgs }