import React, { useState, useEffect, useContext } from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { 
    makeStyles,
    Container,
    Grid,
    Stepper,
    Step,
    StepButton,
    TextField,
    Button,
    InputLabel,
    FormHelperText,
    Link,
    LinearProgress,
    Popover,
    FormControl,
    Select,
    MenuItem
} from "@material-ui/core"
import { useParams, useNavigate } from "react-router-dom";
import Page from 'src/components/Page'
import request from 'src/utils/request'
import appContext from 'src/AppContext'
import UploadImage from 'src/utils/uploadImage'
import { DataGrid } from '@material-ui/data-grid'
import { inputArgs, themeArgs } from './staticData'


const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      padding: theme.spacing(3)
    },
    Container: {
      backgroundColor: theme.palette.background.default,
      padding: 20
    },
    pageTitle: {
      paddingBottom: 20
    },
    form: {
      paddingTop: 20
    },
    tabs: {
      marginTop: 24,
      marginBottom: 36
    },
    table: {
      backgroundColor: '#ffffff'
    },
  }));

  const steps = ['创建活动，填写活动内容', '生成活动券码', '为活动开奖', '查看中间名单']

  const formatDate = (d) => {
    let month = d.getMonth() + 1
    let date = d.getDate()
    let hours = d.getHours()
    let minutes = d.getMinutes()
    month = month > 9 ? month : `0${month}`
    date = date > 9 ? date : `0${date}`
    hours = hours > 9 ? hours : `0${hours}`
    minutes = minutes > 9 ? minutes : `0${minutes}`

    return `${d.getFullYear()}-${month}-${date}T${hours}:${minutes}`
  }

  const ActivitiesEdit = () => {
    const classes = useStyles()
    let { id } = useParams()
    const navigate = useNavigate()
    let pageTitle = id !== undefined ? '设置活动' : '新建活动'

    const context = useContext(appContext)
    const [data, setData] = useState({
      name: '',
      entry_pic: '',
      intro_pic: '',
      rule_pic: '',
      bg_intro_pic: '',
      bg_put_pic: '',
      bg_result_pic: '',
      prize_name: '',
      put_begin_time: '',
      put_end_time: '',
      put_begin_time_format: '',
      put_end_time_format: '',
      max_coin_per_user: '',
      max_coin_all: ''
    })
    const [activeStep, setActiveStep] = React.useState(0)
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(0)
    const [drawPic, setDrawPic] = useState()
    const [winList, setWinList] = useState()
    const [anchorEl, setAnchorEl] = useState()
    const popoverOpen = Boolean(anchorEl)
    const popoverId = popoverOpen ? 'popover' : undefined;

    const [theme, setTheme] = useState({})
    
    useEffect(() => {
      if (id) {
        request({
          url: `/ajax/lottery/info?id=${id}`,
          method: 'get',
        }).then(res => {
            let data = res.data
            if (data) {
              if (data.put_begin_time) {
                data.put_begin_time_format = formatDate(new Date(data.put_begin_time * 1000))
              }

              if (data.put_end_time) {
                data.put_end_time_format = formatDate(new Date(data.put_end_time * 1000))
              }

              if (res.data.draw_pic) {
                setDrawPic(res.data.draw_pic)
              }
              
              setData(res.data)
              let status = res.data.draw_time ? 3
                    : res.data.ticket_prefix ? 2
                    : 1
              setStatus(status)
              updateStep(status)

              if (res.data.theme) {
                let theme = res.data.theme
                if (JSON.stringify(theme) !== '{}') {
                  setTheme(theme)
                } else {
                  initTheme()
                }
              }
              
            }
            setLoading(false)
        }, err => {
            context.showToast(err)
        })
      } else {
        initTheme()
        setLoading(false)
      }
      
    }, [])

    useEffect(() => {
      if (activeStep === 3) {
        request({
          url: `/ajax/lottery/winners?id=${id}`,
          method: 'get'

        }).then(res => {
          setWinList(res.data.list.map((item, index) => {
            item.id = index
            return item
          }))
        }, err => {
          context.showToast(err.message)
        })
      }
    }, [activeStep])

    const updateStep = (index) => {
      setActiveStep(index)
      window.location.hash = index
    }

    const initTheme = () => {
      let t = {}
      themeArgs.forEach(item => {
        t[item.key] = item.defaultValue
      })
      setTheme(t)
    }

    const updateTheme = (key, value) => {
      let t = Object.assign({}, theme)
      t[key] = value
      console.log(t)
      setTheme(t)
    }



    return <Page
        className={classes.root}
        title={pageTitle}
      >
      <Container maxWidth={false}>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={6}>
            <h2 className={classes.pageTitle}>
              {pageTitle}
            </h2>
          </Grid>
          <Grid item>
            <Button aria-describedby={popoverId} variant="contained" color="primary" 
              onClick={(e) => {
                setAnchorEl(e.currentTarget)
              }}>预览活动页面</Button>
            <Popover
              id={popoverOpen}
              open={popoverOpen}
              anchorEl={anchorEl}
              onClose={() => {
                setAnchorEl(null)
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Grid direction="row" container justify="space-around" style={{width: 300, height: 120}}>
                <Grid direction="column" item container xs={4}>
                  <Grid item>
                      <img style={{width: 84}} src={`https://manage.lmtest.cn/ajax/lottery/qr/${id}/index`} />
                  </Grid>
                  <Grid item>首页</Grid>
                </Grid>
                <Grid direction="column" item container xs={4}>
                  <Grid item>
                      <img style={{width: 84}} src={`https://manage.lmtest.cn/ajax/lottery/qr/${id}/play`} />
                  </Grid>
                  <Grid item>投币页</Grid>
                </Grid>
                <Grid direction="column" item container xs={4}>
                  <Grid item>
                      <img style={{width: 84}} src={`https://manage.lmtest.cn/ajax/lottery/qr/${id}/result`} />
                  </Grid>
                  <Grid item>结果页</Grid>
                </Grid>
              </Grid>
            </Popover>
          </Grid>
        </Grid>
        

        <Stepper style={{marginBottom: 20}} activeStep={activeStep} nonLinear>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton onClick={() => {
                updateStep(index)
              }} completed={
                id && index === 0 ? true
                  : status >1 && index === 1 ? true
                  : status >2 && index === 2 ? true
                  : false
              }>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && <>
          {!loading && data && <Formik
            initialValues={data}
            validationSchema={Yup.object().shape({
              max_coin_all: Yup.number().integer('请填写整数').min(1, '请填写大于0的整数'),
              max_coin_per_user: Yup.number().integer('请填写整数').min(1, '请填写大于0的整数')
            })}
            onSubmit={(values, { setSubmitting }) => {
              if (values.put_begin_time_format) {
                values.put_begin_time = new Date(values.put_begin_time_format).getTime()/1000
              }

              if (values.put_end_time_format) {
                values.put_end_time = new Date(values.put_end_time_format).getTime()/1000
              }

              if (id) {
                values.id = id
              }

              values.theme = JSON.stringify(theme)

              request({
                url: '/ajax/lottery/save',
                data: values
              }).then(res => {
                setSubmitting(false)
                context.showToast('保存成功')

                setTimeout(() => {
                  if (!id) {
                    navigate(`/app/activities/edit/${res.data.id}`, { replace: true });
                  }
                }, 500)
              }, err => {
                setSubmitting(false)
                context.showToast(err.message)
              })
            }}>
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values
              }) => (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Grid container direction="row" spacing={6}>
                    <Grid item container direction="column" xs={6} spacing={2}>
                      {inputArgs.map((item, index) => {
                        return <>
                          {item.type === 'text' && <Grid item container>
                            <TextField
                            fullWidth
                            label={item.name} 
                            name={item.key}
                            value={values[item.key]}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched[item.key] && errors[item.key])}
                            helperText={touched[item.key] && errors[item.key]}/>
                          </Grid>}
                          {item.type === 'time' && <Grid item container direction="column" >
                            <InputLabel shrink>{item.name}</InputLabel>
                            <TextField
                              type={"datetime-local"}
                              fullWidth
                              name={item.key}
                              value={values[item.key]}
                              onChange={handleChange}
                            />
                            </Grid>}
                        </>
                      })}
                    </Grid>
                    <Grid item container direction="row" xs={6} spacing={1}>
                      {inputArgs.map((item, index) => {
                        return <>{item.type === 'image' && <Grid item container direction="column" 
                          xs={item.key === 'entry_pic'? 10
                            : 4} spacing={0}>
                          <InputLabel shrink>{item.name}</InputLabel>
                          <UploadImage 
                            width={item.width || 100}
                            height={item.height || 100}
                            prefix="activities/"
                            text={`上传${item.name}`}
                            image={values[item.key]}
                            onChange={(val) => {
                              setFieldValue(item.key, val)
                            }}
                            ></UploadImage>
                        </Grid>}</>
                      })}
                    </Grid>
                    <Grid item container direction="row" xs={12} spacing={2}>
                      <Grid item xs={12}>高级设置</Grid>
                      {themeArgs.map((item, index) => {
                        return <>
                          {item.type === 'text' && <Grid item xs={2}>
                          <TextField
                            fullWidth
                            label={item.name} 
                            name={item.key}
                            style={{background: theme[item.key]}}
                            defaultValue={theme[item.key]}
                            onChange={(e) => {
                              updateTheme(item.key, e.target.value)
                            }} />
                        </Grid>}
                          {item.type === 'boolean' && <Grid item xs={2}>
                            <FormControl style={{width: '100%'}}>
                              <InputLabel>{item.name}</InputLabel>
                              <Select
                                defaultValue={theme[item.key]}
                                onChange={(e) => {
                                  updateTheme(item.key, e.target.value)
                                }}
                              >
                                <MenuItem value={true}>是</MenuItem>
                                <MenuItem value={false}>否</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>}
                          {item.type === 'image' && <Grid item container direction="column" xs={7} >
                            <InputLabel shrink>{item.name}</InputLabel>
                            <UploadImage 
                              width={item.width || 100}
                              height={item.height || 100}
                              prefix="activities/"
                              text={`上传${item.name}`}
                              image={theme[item.key] ? theme[item.key].replace('https://pic.lmtest.cn/', '') : ''}
                              onChange={(val) => {
                                if (val) {
                                  updateTheme(item.key, 'https://pic.lmtest.cn/' + val)
                                } else {
                                  val = ''
                                }
                              }}
                              ></UploadImage></Grid>}
                        
                        </>
                      })}
                    </Grid>
                    <Grid item xs={10}>
                      <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>保存</Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            
          </Formik>}
        </>}
        {activeStep === 1 && <>
          <Formik
            initialValues={{
              prefix: data.ticket_prefix
            }}
            onSubmit={(values, { setSubmitting }) => {
              request({
                url: `/ajax/lottery/${id}/print`,
                data: values
              }).then(res => {
                setSubmitting(false)
                context.showToast('操作成功')
                setStatus(2)
                
              }, err => {
                setSubmitting(false)
                context.showToast(err.message)
              })
            }}>
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Grid container direction="column" justify="center" alignContent="center" xs={12} spacing={2}>
                      <Grid item xs={4}>
                        <TextField
                          required
                          fullWidth
                          disabled={data.ticket_prefix}
                          label="号码前缀" 
                          name="prefix"
                          placeholder="非必须，但建议填写，最长6位"
                          value={values.prefix}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.prefix && errors.prefix)}
                          helperText={touched.prefix && errors.prefix}/>
                      </Grid>  
                    <Grid item>
                      <FormHelperText>
                        注意：
                      </FormHelperText>
                      <FormHelperText>
                      1. 本次活动每个用户最大投币数为{data.max_coin_per_user},全活动最大投币数为{data.max_coin_all},如修改请<Link
                        onClick={() => {
                          updateStep(0)
                        }}>
                      返回上一步</Link>
                      </FormHelperText>
                      <FormHelperText>
                      2. 生成券码仅操作一次，之后不可再次生成
                      </FormHelperText>
                      <FormHelperText>
                        3. 生成时间可能很长，请耐心等待，不要关闭页面
                      </FormHelperText>
                    </Grid>
                    <Grid item>
                      <Button 
                      type="submit" disabled={isSubmitting || status >= 2}
                      color="primary" variant="contained">开始生成券码</Button>
                    </Grid>
                  </Grid>
                </form>)
              }

          </Formik>
          {status >= 2 && <Grid container direction="row" justify="center" style={{marginTop: 24}} >
            <Grid item container direction="column" xs="5" spacing={2}>
              <Grid item>
                当前券码发放进度{data.bet_cnt || 0}/{data.max_coin_all}
              </Grid>
              <Grid item alignItems="center" alignContent="center">
                <LinearProgress color="primary" variant="determinate" value={Math.ceil((data.bet_cnt || 0)/data.max_coin_all * 100) } />
              </Grid>
            </Grid>
            
          </Grid>}
        </>}
        {activeStep === 2 && <>
          <Formik
            initialValues={{
              pic: data.draw_pic,
              number: data.win_code,
              message: data.draw_msg
            }}
            validationSchema={Yup.object().shape({
              number: Yup.number().required().integer().min(0, '请填写0-99之间的整数').max(99, '请填写0-99之间的整数'),
              message: Yup.string().required()
            })}
            onSubmit={(values, { setSubmitting }) => {
              if (!values.pic) {
                context.showToast('请上传中奖图')
                return
              }

              request({
                url: `/ajax/lottery/${id}/draw`,
                data: values
              }).then(res => {
                setSubmitting(false)
                context.showToast('开奖成功')
                setStatus(3)
              }, err => {
                setSubmitting(false)
                context.showToast(err.message)
              })
            }}>
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values
              }) => (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Grid container direction="column" justify="center" alignContent="center" spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel shrink required>中奖图片</InputLabel>
                      <UploadImage 
                        width={200}
                        height={150}
                        prefix="activities/"
                        text={`上传中奖图片`}
                        image={drawPic}
                        disabled={status >= 3}
                        onChange={(val) => {
                          // setDrawPic(val)
                          setFieldValue('pic', val)
                        }}
                        ></UploadImage>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          label="中奖尾号（0-99）" 
                          name="number"
                          value={values.number}
                          disabled={status >= 3}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.number && errors.number)}
                          helperText={touched.number && errors.number}/>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                      required
                          fullWidth
                          label="中奖消息" 
                          name="message"
                          value={values.message}
                          disabled={status >= 3}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.message && errors.message)}
                          helperText={touched.message && errors.message}/>
                    </Grid>
                    <Grid item>
                      <Button color="primary" variant="contained" type="submit" disabled={isSubmitting || status >= 3}>开奖</Button>
                    </Grid>
                  </Grid>

                </form>
              )}
          </Formik>
        </>} 
        {activeStep === 3 && <>
          {winList && <DataGrid
            disableSelectionOnClick={true}
            autoHeight
            className={classes.table}
            rows={winList}
            columns={[
              {
                field: 'uid',
                headerName: 'id',
                width: 100
              },
              {
                field: 'nickname',
                headerName: '昵称',
                width: 240
              },
              {
                field: 'avatar',
                headerName: '头像',
                width: 80,
                renderCell: (params) => {
                  return <img style={{width: 45}} src={params.row.avatar} />
                }
              },
              {
                field: 'name',
                headerName: '姓名',
                width: 120
              },
              {
                field: 'phone',
                headerName: '手机号',
                width: 200
              },
              {
                field: 'wechat',
                headerName: '微信号',
                width: 200
              },
              {
                field: 'tickets',
                headerName: '中奖券码',
                width: 400,
                valueGetter: (params) => {
                  
                  return params.row.tickets
                }
              },

            ]}
          >
          </DataGrid>}
        </>}
      </Container>
    </Page>
  }

  export default ActivitiesEdit