import React, { useState, useEffect, useContext } from 'react'
import { 
    makeStyles,
    Container,
    Grid,
    TextField,
    InputAdornment,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core"
import Page from 'src/components/Page'
import { DataGrid } from '@material-ui/data-grid'
import request from 'src/utils/request'
import SearchIcon from '@material-ui/icons/Search';
import { Formik } from 'formik'
import * as Yup from 'yup'
import appContext from 'src/AppContext'
import Pager from 'src/utils/Pager'


const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      padding: theme.spacing(3)
    },
    table: {
      backgroundColor: '#ffffff'
    },
    toolbar: {
      marginBottom: 12
    },
    select: {
      width: 100,
      '&::before' : {
        display: 'none'
      }
    }
  }));

const User = () => {
    const classes = useStyles()
    const context = useContext(appContext)

    const searchSetting = [
      {
        label: 'ID搜索',
        key: 'id',
        xs: 2
      }, {
        label: '用户名搜索',
        key: 'username',
        xs: 2
      }, {
        label: '手机号搜索',
        key: 'mobile',
        xs: 2
      }
    ]

    const [list, setList] = useState()
    const [reload, setReload] = useState(false)
    const [rowCount, setRowCount] = useState(0)
    const [offset, setOffset] = useState(0)
    const [loading, setLoading] = useState(false)
    const [keyword, setKeyword] = useState({})
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialog, setDialog] = useState({})
    const [type, setType] = useState('1')

    const updateKeyword = (key, value) => {
      let copy = Object.assign({}, keyword)
      copy[key] = value
      setKeyword(copy)
    }

    const goReload = () => {
      setReload(false)
      setTimeout(() => {
        setReload(true)
      })
    }

    const updateList = (rowIndex, key, value) => {
      let copy = list.concat()
      copy[rowIndex][key] = value
      setList(copy)
    }

    useEffect(() => {
      setLoading(true)
      request({
        url: '/ajax/user/list',
        method: 'get',
        data: {
          limit: 20,
          offset,
          username: keyword.username || '',
          id: keyword.id || '',
          mobile: keyword.mobile || ''
        }
      }).then(res => {

        if (offset === 0) {
          goReload()
        }

        if (res.data && res.data.users) {
          setList(res.data.users.map((item, index) => {
            item.uid = item.id
            item.id = index
            return item
          }))
          setRowCount(res.data.total)
        }
        setLoading(false)
      })

    }, [offset, keyword])

    const closeDialog = () => {
      setDialogOpen(false)
    }
    
    return <Page
        className={classes.root}
        title="用户管理"
    >
      <Container maxWidth={false}>
        <Grid container direction="column" spacing={2} className={classes.toolbar}>
          <Grid item container direction="row" spacing={1}>
            {searchSetting.map(item => {
              return <Grid item xs={item.xs} >
                <TextField
                  label={item.label}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  placeholder="输入后按回车"
                  onKeyUp={(e) => {
                    let value = e.target.value

                    if (e.key === 'Enter') {
                      if  (item.key === 'id')  {
                        if (!value.match(/^\d*$/)) {
                          context.showToast('请输入数字')
                          return
                        }
                      }

                      updateKeyword(item.key, value)
                      setOffset(0)
                    }

                    if (value === '') {
                      updateKeyword(item.key, '')
                      setOffset(0)
                    }
                  }}
                ></TextField>
              </Grid>
            })}
            
          </Grid>
          <Grid item>{reload && <DataGrid
            components={{
              footer: Pager
            }}
            disableSelectionOnClick={true}
            className={classes.table}
            autoHeight
            rowHeight={80}
            rows={list}
            columns={[
              {
                field: 'uid',
                headerName: 'id',
                width: 150
              },
              {
                field: 'username',
                headerName: '用户名',
                width: 180,
              },
              {
                field: 'avatar',
                headerName: '头像',
                width: 72,
                renderCell: (params) => {
                  return <div>
                    <img style={{width: 40, height: 40, marginTop: 20}} src={params.row.avatar} />
                    </div>
                }
              },
              {
                field: 'gender',
                headerName: '性别',
                width: 72,
                valueGetter: (params) => {
                  return params.row.gender === 1 ? '男' : '女'
                }
              },
              {
                field: 'mobile',
                headerName: '手机号',
                width: 180,
              },
              {
                field: 'coins',
                headerName: '蓝莓币',
                width: 84,
              },
              {
                field: 'action',
                headerName: '操作',
                width: 200,
                renderCell: (params) => {
                  return <Grid container direction="row">
                    <Button 
                      variant="contained" color="primary" size="small"
                      onClick={() => {
                        setDialogOpen(true)
                        setDialog({
                          data: params.row,
                          index: params.rowIndex
                        })
                      }}>增减蓝莓币</Button>
                  </Grid>
                }
              },

            ]}
            pagination
            paginationMode="server"
            pageSize={20} 
            rowCount={rowCount}
            onPageChange={(params) => {
              setOffset((params.page - 1) * 20)
            }}
            rowsPerPageOptions={[]}
            loading={loading}
          ></DataGrid>}</Grid>
        </Grid>
      </Container>
      <Dialog open={dialogOpen}>
        <DialogTitle>增减蓝莓币</DialogTitle>
        <Formik 
          initialValues={{
            type: '0',
            num: '',
            desc: ''
          }}
          validationSchema={Yup.object().shape({
            num: Yup.number().required('请填写数量').min(1, '数量必须大于1'),
            desc: Yup.string().required('请填写备注')
          })}
          onSubmit={(values, { setSubmitting }) => {
            let finalNum = values.type === '0' ? values.num : (-values.num)
            if ((dialog.data.coins + finalNum) < 0) {
              context.showToast('用户蓝莓币数量不能小于0，请检查')
              setSubmitting(false)
              return
            }

            request({
              url: `/ajax/user/${dialog.data.uid}/coins`,
              method: 'post',
              data: {
                num: finalNum,
                desc: values.desc
              }
            }).then(res => {
              context.showToast('操作成功')
              updateList(dialog.index, 'coins', (dialog.data.coins + parseInt(finalNum)))
              closeDialog()
            }, err => {
              context.showToast(err.message)
            })
          }}>
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent style={{width: 400, height: 300}}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item container direction="row" spacing={1} alignItems="center">
                      <Grid item>
                        <img src={dialog.data.avatar} style={{width: 30}} />
                      </Grid>
                      <Grid item>
                        <p>{dialog.data.username}</p></Grid>
                    </Grid>
                    <Grid item>
                      <p>当前蓝莓币: {dialog.data.coins}
                        {values.num && !errors.num && <>
                          {values.type === '0' && <span style={{color: 'green'}}> (+{values.num})</span> }
                          {values.type === '1' && <span style={{color: 'red'}}> (-{values.num})</span>}
                        </>}
                      </p>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={2}>
                        <FormControl>
                          <InputLabel>操作</InputLabel>
                          <Select
                            value={values.type}
                            name="type"
                            onChange={handleChange}>
                            <MenuItem value={`0`}>增加</MenuItem>
                            <MenuItem value={`1`}>减少</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          fullWidth
                          label="蓝莓币数量" 
                          name="num"
                          value={values.num}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.num && errors.num)}
                          helperText={touched.num && errors.num}
                          ></TextField>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <TextField 
                        fullWidth
                        label="备注"
                        name="desc"
                        value={values.desc}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.desc && errors.desc)}
                        helperText={touched.desc && errors.desc}
                        ></TextField>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => {
                    closeDialog()
                  }} color="default">取消</Button>
                  <Button type="submit" disable={isSubmitting}
                    color="primary" variant="contained">保存</Button>
                </DialogActions>
              </form>
            )}
        </Formik>
      </Dialog>
    </Page>
}

export default User