import React, {useEffect, useState, useContext} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  makeStyles,
  TextField,
  Grid,
  Button,
  InputLabel,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormHelperText,
  Link,
  Tabs,
  Tab
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useParams, useNavigate } from "react-router-dom";
import UploadImage from 'src/utils/uploadImage'
import request from 'src/utils/request'
import appContext from 'src/AppContext'
import Overview from 'src/views/articles/overview'

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      padding: theme.spacing(3)
    },
    Container: {
      backgroundColor: theme.palette.background.default,
      padding: 20
    },
    pageTitle: {
      paddingBottom: 20
    },
    form: {
      paddingTop: 20
    },
    tabs: {
      marginTop: 24,
      marginBottom: 36
    }
  }));

const formatDate = (d) => {
  let month = d.getMonth() + 1
  let date = d.getDate()
  month = month > 9 ? month : `0${month}`
  date = date > 9 ? date : `0${date}`

  return `${d.getFullYear()}-${month}-${date}`
}

const ArticlesEdit = () => {
    const classes = useStyles();
    let { id } = useParams();
    const navigate = useNavigate();
    let pageTitle = id !== undefined ? '编辑文章' : '添加文章'
    const [tags, setTags] = useState([])
    const [data, setData] = useState({
      "id": id || 0,
      "type": "", //类型: EVAL测评, OTHER其他
      "url": "", //文章url
      "cover": "", //封图，七牛文件key
      "title": "", 
      "tag_ids": "",  //关联tag id,多个用英文逗号分隔
      "digest": "", //是否精选 0否 1是
      "tags": {}, 
      "eval_state": "",
      "pub_time": "",
      "pub_time_format": formatDate(new Date())
    })
    const [loading, setLoading] = useState(true)
    const context = useContext(appContext)
    const [tab, setTab] = useState('tab1');


    useEffect(() => {
      if (window.location.hash) {
        setTab(window.location.hash.replace('#', ''))
      } else {
        setTab('tab1')
      }
    }, [window.location.hash])

    useEffect(() => {
      if (tab === 'tab1') {
        request({
          url: '/ajax/tag/list',
          method: 'get'
        }).then(res => {
          if (res.data) {
            setTags(res.data.list)
          }
        })
      }
      
    }, [tab])

    const getTags = (data) => {
      let tags = {}
      if (data.tag_ids) {
        data.tag_ids.split(',').forEach(item => {
          tags[item.toString()] = true
        })
      }
      return tags
    }

    const getPubTimeFormat = (data) => {
      return data.pub_time ? formatDate(new Date(data.pub_time*1000)) : ''
    }

    useEffect(() => {
      if (id) {
        request({
          url: '/ajax/post/info',
          method: 'get',
          data: {
            id
          }
        }).then(res => {
          if (res.data) {
            res.data.tags = getTags(res.data)
            res.data.pub_time_format = getPubTimeFormat(res.data)
            setData(res.data)
          }
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
      
    }, [id])


    return <Page className={classes.root} title={pageTitle} >
      <Container className={classes.Container} maxWidth={false}>
        <h2 className={classes.pageTitle}>{pageTitle}</h2>
        <hr/>

        <Tabs 
          className={classes.tabs}
          value={tab} 
          onChange={(event, newValue) => {
            setTab(newValue)
            window.location.hash = newValue
          }}
          variant="fullWidth" 
          indicatorColor="primary"
          >
          <Tab label="基础信息" value={'tab1'} />
          <Tab disabled={id === undefined} label="排名&冠军" value={'tab2'} />

        </Tabs>

        {!loading && data && tab === 'tab1' && <Formik
          initialValues={data}
          validationSchema={Yup.object().shape({
            title: Yup.string().required('请输入文章标题'),
            url: Yup.string().required('请输入文章链接'),
            // cover: Yup.string().required('请上传封图'),
            type: Yup.string().required('请选择类型'),
            digest: Yup.string().required('请选择是否精选')
          })}
          onSubmit={(values, { setSubmitting }) => {
            values.tag_ids = Object.keys(values.tags).filter(tid => {
              return values.tags[tid]
            }).join(',')
            if (values.pub_time_format) {
              values.pub_time = Math.floor(new Date(values.pub_time_format).getTime()/1000)
            }
            console.log(values.pub_time)
            request({
              url: '/ajax/post/save',
              data: values
            }).then(res => {
              setSubmitting(false)
              context.showToast('保存成功')
              setTimeout(() => {
                if (!id) {
                  navigate('/app/articles', { replace: true });
                }
              }, 500)
            }, err => {
              setSubmitting(false)
              context.showToast(err.message)
            })
          }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={3}>
                  <Grid container item xs={8}>
                    <TextField 
                      fullWidth
                      label="文章标题" 
                      name="title"
                      value={values.title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>
                  <Grid container item xs={8}>
                    <TextField 
                      fullWidth
                      label="文章链接" 
                      name="url"
                      value={values.url}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.url && errors.url)}
                      helperText={touched.url && errors.url}
                    />
                  </Grid>
                  <Grid container item xs={8} direction="column">
                    <InputLabel shrink>封图</InputLabel>
                    <UploadImage 
                      width={300} 
                      height={120} 
                      prefix="cover/"
                      image={values.cover}
                      onChange={(val) => {
                        values.cover = val
                      }}
                      error={Boolean(touched.cover && errors.cover)}
                      ></UploadImage>
                      <FormHelperText>如果不上传封图，将启用默认封图</FormHelperText>
                  </Grid>
                  <Grid container item xs={8} direction="column">
                    <InputLabel shrink>选择类别</InputLabel>
                    <Grid container item>
                      {tags.length > 0 && tags.map(item => {
                        return <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedB"
                              color="primary"
                              defaultChecked={values.tags ? values.tags[item.id.toString()] : false}
                            />
                          }
                          label={item.name}
                          onChange={(e) => {
                            values.tags[item.id.toString()] = e.target.checked
                          }}
                        />
                      })}
                      {tags.length === 0 && 
                        <FormHelperText>暂无分类，可以去<Link href="/app/categories" target="_blank">分类管理</Link>创建</FormHelperText>
                      }
                    </Grid>
                  </Grid>
                  <Grid container item xs={8} direction="column">
                    <InputLabel shrink>文章类型</InputLabel>
                    <RadioGroup 
                      name="type" 
                      row 
                      defaultValue={Object.assign({}, values).type}
                      onChange={handleChange}
                      >
                      <FormControlLabel value="EVAL" control={<Radio />} label="测评" />
                      <FormControlLabel value="OTHER" control={<Radio />} label="其他" />
                    </RadioGroup>
                    {touched.type && errors.type && <FormHelperText error>{errors.type}</FormHelperText>}
                  </Grid>
                  <Grid container item xs={8} direction="column">
                    <InputLabel shrink>是否设置精选</InputLabel>
                    <RadioGroup
                      name="digest"
                      row
                      defaultValue={Object.assign({}, values).digest.toString()}
                      onChange={handleChange}
                    >
                      <FormControlLabel value={'0'} control={<Radio />} label="否" />
                      <FormControlLabel value={'1'} control={<Radio />} label="是" />
                    </RadioGroup>
                    {touched.digest && errors.digest && <FormHelperText error>{errors.digest}</FormHelperText>}
                  </Grid>
                  <Grid container item xs={8} direction="column">
                    <InputLabel shrink>是否开启中间页</InputLabel>
                    <RadioGroup
                      name="eval_state"
                      row
                      defaultValue={Object.assign({}, values).eval_state.toString()}
                      onChange={handleChange}
                    >
                      <FormControlLabel value={'0'} control={<Radio />} label="关闭" />
                      <FormControlLabel value={'1'} control={<Radio />} label="开启" />
                    </RadioGroup>
                    {touched.eval_state && errors.eval_state && <FormHelperText error>{errors.eval_state}</FormHelperText>}
                  </Grid>
                  <Grid container item xs={8} direction="column">
                    <InputLabel shrink>发布时间</InputLabel>
                    <TextField
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="pub_time_format"
                      value={values.pub_time_format}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid container item xs={8} direction="column">
                    <TextField 
                      fullWidth
                      label="简介（用于分享图）" 
                      name="summary"
                      value={values.summary}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.summary && errors.summary)}
                      helperText={touched.summary && errors.summary}
                      multiline
                      rows={5}
                    />
                  </Grid>
                  <Grid item xs={2}>
                  <Button color="primary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained">保存</Button>
                  </Grid>
                  
              </Grid>
              </form>
            )}
          
        </Formik>
        }
        {tab === 'tab2' && <Overview />}
      </Container>
    </Page>
}

export default ArticlesEdit;