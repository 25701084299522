import React, { useState, useContext, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Container,
  makeStyles,
  Grid,
  TextField,
  Button,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Checkbox,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core'
import Page from 'src/components/Page'
import appContext from 'src/AppContext'
import { useParams } from "react-router-dom"
import UploadImage from 'src/utils/uploadImage'
import request from 'src/utils/request'
import LinkInput from 'src/components/LinkInput'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  Container: {
    backgroundColor: theme.palette.background.default,
    padding: 20
  },
  pageTitle: {
    paddingBottom: 20
  },
  form: {
    paddingTop: 20
  }
}))

let formList = [
    {
        key: 'title',
        text: '标题',
        type: 'text',
        defaultValue: ''
    },
    {
        key: 'url',
        text: '跳转地址',
        type: 'link',
        defaultValue: ''
    },
    {
        key: 'cover',
        text: '封图',
        prefix: 'xxx/xxx/',
        type: 'image',
        defaultValue: ''
    },
    {
        key: 'media',
        text: '音频',
        prefix: 'xxx/xxx/',
        type: 'audio',
        defaultValue: ''
    },
    {
        key: 'category',
        text: '单选-radio',
        type: 'radio',
        defaultValue: '1',
        options: [{
            key: '1',
            text: '分类1'
        }, {
            key: '2',
            text: '分类2'
        }]
    },
    {
        key: 'category',
        text: '单选-select',
        type: 'select',
        defaultValue: '1',
        options: [{
            key: '1',
            text: '分类1'
        }, {
            key: '2',
            text: '分类2'
        }]
    },
    {
        key: 'tag',
        text: '多选',
        type: 'checkbox',
        defaultValue: '1',
        options: [{
            key: '1',
            text: '标签1',
            checked: true
        }, {
            key: '2',
            text: '标签2'
        }]
    },
    {
        key: 'pubdate',
        text: '日期',
        type: 'date',
        defaultValue: ''
    },
    {
        key: 'pubtime',
        text: '日期时间',
        type: 'datetime',
        defaultValue: ''
    },
    {
        key: 'desc',
        text: '多行文本',
        type: 'text',
        rows: 5,
        defaultValue: ''
    }
]

const Example = () => {
    const classes = useStyles()
    const context = useContext(appContext)

    let { id } = useParams()
    let pageTitle = id !== undefined ? '编辑xx' : '添加xx'

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (id) {
            // request({
            //     url: '/ajax/boke/info',
            //     method: 'get',
            //     data: {
            //       id
            //     }
            // }).then(res => {
            //     setData(res.data)
            //     setLoading(false)
            // })
            setLoading(false)
        } else {
            setDefaultParams()
            setLoading(false)
        }
    }, [])

    const setDefaultParams = () => {
        // 表单不填却需要上报的参数，或者特殊定制的输入，都可以放在这里
        let d = {
            id: 0
        }
        formList.forEach(item => {
            d[item.key] = item.defaultValue
        })
        setData(d)
    }
    

    return <Page className={classes.root} title={pageTitle}>
        <Container maxWidth={false} className={classes.Container}>
            <h2 className={classes.pageTitle}>{pageTitle}</h2>
            <hr/>
            {!loading && data && <Formik
                initialValues={data}
                validationSchema={Yup.object().shape({

                })}
                onSubmit={(values, { setSubmitting }) => {
                    console.log(values)
                }}>
                {
                    ({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setFieldError,
                        touched,
                        values
                    }) => (
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" spacing={2}>
                                {formList.map(item => (
                                    <>{item.type === 'text' && <Grid container item xs={4}>
                                        <TextField
                                            fullWidth
                                            label={item.text} 
                                            name={item.key}
                                            value={values[item.key]}
                                            multiline={item.rows? true : false}
                                            rows={item.rows}
                                            placeholder={item.placeholder}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched[item.key] && errors[item.key])}
                                            helperText={touched[item.key] && errors[item.key]}/>
                                        </Grid>}
                                        {item.type === 'link' && <Grid container item xs={4} spacing={1} direction="column">
                                            <Grid item>
                                                <InputLabel shrink>{item.text}</InputLabel>
                                            </Grid>
                                            <Grid item>
                                                <LinkInput defaultValue={item.defaultValue} onChange={(v) => {
                                                    setFieldValue(item.key, v)
                                                    setFieldError(item.key, null)
                                                }}></LinkInput>
                                                {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                            </Grid>
                                        </Grid>}
                                        {(item.type === 'image' || item.type === 'audio') && <Grid container item xs={4} direction="column">
                                            <InputLabel shrink>{item.text}</InputLabel>
                                            <UploadImage 
                                                width={100} 
                                                height={100} 
                                                prefix={item.prefix}
                                                image={values[item.key]}
                                                text={`上传${item.text}`}
                                                type={item.type}
                                                onChange={(val) => {
                                                    setFieldValue(item.key, val)
                                                }}
                                                error={Boolean(touched[item.key] && errors[item.key])}></UploadImage>
                                        </Grid>}
                                        {item.type === 'radio' && <Grid container item xs={8} direction="column">
                                            <InputLabel shrink>{item.text}</InputLabel>
                                            <RadioGroup 
                                                name={item.key} 
                                                row 
                                                value={values[item.key]}
                                                onChange={handleChange}>
                                                {item.options.length > 0 && item.options.map(op => {
                                                return <FormControlLabel value={op.key} control={<Radio />} label={op.text} />
                                                })}
                                            </RadioGroup>
                                            {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                        </Grid>}
                                        {item.type === 'select' && <Grid container item xs={2} direction="column">
                                            <FormControl style={{width: '100%'}}>
                                                <InputLabel shrink>{item.text}</InputLabel>
                                                <Select
                                                    defaultValue={item.defaultValue}
                                                    onChange={handleChange}>
                                                    {item.options.map(op => (
                                                        <MenuItem value={op.key}>{op.text}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                        </Grid>}
                                        {item.type === 'checkbox' && <Grid container item xs={8} direction="column">
                                            <InputLabel shrink>{item.text}</InputLabel>
                                            <Grid container item>
                                                {item.options.length > 0 && item.options.map(op => {
                                                    return <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                        name={op.key}
                                                        color="primary"
                                                        defaultChecked={op.checked}/>
                                                    }
                                                    label={op.text}
                                                    onChange={(e) => {
                                                        let arr = values[item.key].split(',')
                                                        let key = e.target.name
                                                        let checked = e.target.checked
                                                        if (checked) {
                                                            arr.push(key)
                                                        } else {
                                                            arr.splice(arr.indexOf(key), 1)
                                                        }
                                                        setFieldValue(item.key, arr.join(','))
                                                        setFieldError(item.key)
                                                    }}/>
                                                })}
                                                {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                            </Grid>
                                        </Grid>}
                                    </>
                                    
                                ))}
                                <Grid item xs={2}>
                                    <Button color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained">保存</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }
            </Formik>}
        </Container>
    </Page>
}

export default Example