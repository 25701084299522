import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  makeStyles,
  Container,
  Tabs,
  Tab,
  Grid,
  Menu,
  MenuItem,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from '@material-ui/core';
import Page from 'src/components/Page';
import AdList from 'src/views/ad/table'
import UploadImage from 'src/utils/uploadImage'
import request from 'src/utils/request'
import appContext from 'src/AppContext'

const adSize = {
  'POPUP': {
    width: 133,
    height: 200
  },
  'FEED': {
    width: 343,
    height: 110
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  tabs: {
    backgroundColor: theme.palette.background.default,
    marginTop: 20,
    marginBottom: 20
  },
  formControl: {
    minWidth: 88,
  },
  confirm: {
    width: 400
  }
}));

const Ad = () => {
  const classes = useStyles();
  const context = useContext(appContext)

  const [tab, setTab] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialog, setDialog] =useState({})
  const [linktype, setLinkType] = useState('0')
  const [reload, setReload] = useState(true)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [position, setPosition] = useState()
  
  const [confirm, setConfirm] = useState({
    open: false,
  })


  useEffect(() => {
    if (window.location.hash) {
      setTab(window.location.hash.replace('#', ''))
    } else {
      setTab('POPUP')
    }
  }, [window.location.hash])

  const formatDate = (d) => {
    let month = d.getMonth() + 1
    let date = d.getDate()
    let hours = d.getHours()
    let minutes = d.getMinutes()
    month = month > 9 ? month : `0${month}`
    date = date > 9 ? date : `0${date}`
    hours = hours > 9 ? hours : `0${hours}`
    minutes = minutes > 9 ? minutes : `0${minutes}`

    return `${d.getFullYear()}-${month}-${date}T${hours}:${minutes}`
  }

  const tomorrow = () => {
    let today = new Date()
    today.setDate(today.getDate() + 1)
    return today
  }


  const openDialog = (type, adtype, data) => {
    dialog.open = true
    dialog.type = type
    dialog.adtype = adtype

    if (data) {

      if (data.url && data.url !== '') {
        setLinkType('0')
      }

      if (data.jump_appid && data.jump_appid !== '') {
        setLinkType('1')
      }

      console.log(data)

      data.begin_time_format = formatDate(new Date(data.begin_time * 1000))
      data.end_time_format = formatDate(new Date(data.end_time * 1000))
    }

    dialog.data = data || {
      id: 0,
      type: adtype,
      position: "",
      cover: "",
      title: "",
      url: "",
      jump_appid: "",
      jump_path: "",
      begin_time_format: formatDate(new Date()),
      end_time_format: formatDate(tomorrow())
    }

    setPosition(dialog.data.position)
    setDialog(dialog)
    setDialogOpen(true)
  }

  const closeDialog = (callback) => {
    setDialog({})
    setDialogOpen(false)
    if (callback) {
      callback()
    }
  }

  const toReload = () => {
    setReload(false)
    setTimeout(() => {
      setReload(true)
    })
  }

  const closeConfirm = () => {
    setConfirm({
      open: false
    })
  }

  const remove = () => {
    let item = confirm.one
    request({
      url: '/ajax/ad/del',
      method: 'post',
      data: {
        id: item.id
      }
    }).then(res => {
      context.showToast('删除成功')
      toReload()
      setConfirm({
        open: false
      })
    }, err => {
      context.showToast(err.message)
    })
  }

  return (
    <Page
      className={classes.root}
      title="广告管理"
    >
      <Container maxWidth={false}>
        <Grid container justify="flex-end">
          <Button 
            onClick={(event) => {
              event.preventDefault();
              setAnchorEl(event.currentTarget);
            }}
            color="primary"
            variant="contained"
          >
            添加广告
          </Button>
          <Menu
            disableAutoFocus
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(undefined)
            }}
          >
            <MenuItem onClick={() => {
              openDialog('add', 'POPUP')
              setAnchorEl(undefined)
            }}>弹窗广告</MenuItem>
            <MenuItem onClick={() => {
              openDialog('add', 'FEED')
              setAnchorEl(undefined)
            }}>FEED广告</MenuItem>
          </Menu>
        </Grid>
        <Tabs 
          className={classes.tabs}
          value={tab} 
          onChange={(event, newValue) => {
            setTab(newValue)
            window.location.hash = newValue
          }}
          variant="fullWidth" 
          indicatorColor="primary"
          >
          <Tab label="弹窗广告" value={'POPUP'} />
          <Tab label="FEED广告" value={'FEED'} />
        </Tabs>
        
        {tab === 'POPUP' && reload && <AdList key="POPUP" type="POPUP" 
          onEdit={(data) => {
            openDialog('add', 'POPUP', data)
          }} 
          onRemove={(data, index) => {
            setConfirm({
              open: true,
              one: data,
              index
            })
          }}
        
        />}
        <div>
          {tab === 'FEED' && reload && <AdList type="FEED" 
            onEdit={(data) => {
              openDialog('add', 'FEED', data)
            }}
            onRemove={(data, index) => {
              setConfirm({
                open: true,
                one: data,
                index
              })
            }} />}
        </div>
      </Container>
     
      {<Dialog open={dialogOpen}>
        <DialogTitle>{`${dialog.type === 'add' ? `添加` : `编辑`}${dialog.adtype === 'POPUP' ? `弹窗` : `FEED`}广告`}</DialogTitle>
        {dialog.data && <Formik
          key="addialog"
          initialValues={dialog.data}
          validationSchema={Yup.object().shape({
            title: Yup.string().required('请输入标题'),
            cover: Yup.string().required('请上传广告图'),
            position: dialog.adtype === 'FEED'? Yup.number().required('请选择广告位置') : Yup.string(),
            url: linktype === '0' ? Yup.string().required('请填写链接') : Yup.string(),
            jump_appid: linktype === '1' ? Yup.string().required('请填写appid') : Yup.string(),
            jump_path: linktype === '1' ? Yup.string().required('请填写path'): Yup.string()
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (linktype === '0') {
              values.jump_appid = ''
              values.jump_path = ''
            } else {
              values.url = ''
            }

            values.position = position
            values.begin_time = new Date(values.begin_time_format).getTime()/1000
            values.end_time = new Date(values.end_time_format).getTime()/1000

            if (values.begin_time >= values.end_time) {
              context.showToast('结束时间不能小于或等于开始时间')
              setSubmitting(false)
              return
            }

            // console.log(values)
            // return

            request({
              url: '/ajax/ad/save',
              data: values
            }).then(res => {
              setSubmitting(false)
              closeDialog(() => {
                toReload()
              })
              context.showToast('保存成功')
            }, err => {
              setSubmitting(false)
              context.showToast(err.message)
            })
          }}>
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent style={{width: 600, height: 500}}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        label="广告名"
                        fullWidth
                        name="title"
                        value={values.title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    </Grid>
                    <Grid container item justify="space-between">
                      <Grid item xs={2}>
                        <FormControl className={classes.formControl}>
                          <InputLabel>链接类型</InputLabel>
                          <Select
                            defaultValue={(() => {
                              return linktype || '0'
                            })()}
                            onChange={(e) => {
                              setLinkType(e.target.value)
                            }}
                          >
                            <MenuItem value={`0`}>普通</MenuItem>
                            <MenuItem value={`1`}>小程序</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {linktype === '0' && <Grid item xs={10}>
                        <TextField
                          label="链接"
                          fullWidth
                          name="url"
                          value={values.url}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.url && errors.url)}
                          helperText={touched.url && errors.url}
                        />
                      </Grid>}
                      {linktype === '1' && <Grid item xs={5}>
                        <TextField
                          label="APPID"
                          fullWidth
                          name="jump_appid"
                          value={values.jump_appid}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.jump_appid && errors.jump_appid)}
                          helperText={touched.jump_appid && errors.jump_appid}
                        />
                      </Grid>}
                      {linktype === '1' && <Grid item xs={4}>
                        <TextField
                          label="PATH"
                          fullWidth
                          name="jump_path"
                          value={values.jump_path}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.jump_path && errors.jump_path)}
                          helperText={touched.jump_path &&errors.jump_path}
                        />
                      </Grid>}
                    </Grid>
                    <Grid item>
                      <InputLabel shrink>开始时间</InputLabel>
                      <TextField
                        type={"datetime-local"}
                        fullWidth
                        name="begin_time_format"
                        value={values.begin_time_format}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item>
                      <InputLabel shrink>结束时间</InputLabel>
                      <TextField
                        type={"datetime-local"}
                        fullWidth
                        name="end_time_format"
                        value={values.end_time_format}
                        onChange={handleChange}
                      />
                    </Grid>
                    {dialog.adtype === 'FEED' && 
                      <Grid item container direction="column" spacing={1}>
                        <Grid item>
                          <InputLabel shrink={true}>广告位置</InputLabel>
                        </Grid>
                        <Grid item>
                          <ButtonGroup name="position" color="primary">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
                              return <Button 
                                size="medium" 
                                variant={position === index ? 'contained' : 'outlined'}
                                onClick={() => {
                                  if (position !== index) {
                                    setPosition(index)
                                    values.position = index
                                  }
                                }}
                                >{item}</Button>
                            })}
                          </ButtonGroup>
                          {touched.position && errors.position && !position && <FormHelperText error>{errors.position}</FormHelperText>}

                        </Grid>
                        
                      </Grid>
                    }
                    <Grid item>
                      <UploadImage 
                        name="cover"
                        width={adSize[dialog.adtype].width} 
                        height={adSize[dialog.adtype].height} 
                        text={`上传广告图`} 
                        prefix="ad/"
                        image={values.cover}
                        onChange={(val) => {
                          values.cover = val
                        }}
                        error={Boolean(touched.cover && errors.cover)}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => {
                    closeDialog()
                  }} color="default" >
                    取消
                  </Button>
                  <Button type="submit" disabled={isSubmitting} color="primary" variant="contained">
                    保存
                  </Button>
                </DialogActions>
      
              </form>

            )
          }
          
        </Formik>
        }
      </Dialog>
    }
    <Dialog
      open={confirm.open}
      onClose={closeConfirm}
    >
      <DialogTitle>提示</DialogTitle>
      <DialogContent className={classes.confirm}>
        <DialogContentText>
          确定要删除吗？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeConfirm} color="default">
          否
        </Button>
        <Button onClick={() => {
          remove()
        }} color="primary" autoFocus variant="contained">
          是
        </Button>
      </DialogActions>
    </Dialog>
    </Page>
  );
};

export default Ad;
