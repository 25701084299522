import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Tooltip,
  IconButton,
  Grid,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Page from 'src/components/Page';
import request from 'src/utils/request'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import SearchIcon from '@material-ui/icons/Search';
import appContext from 'src/AppContext'
import Pager from 'src/utils/Pager';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff'
  },
  toolbar: {
    marginBottom: 12
  },
  select: {
    width: 100,
    '&::before' : {
      display: 'none'
    }
  },
  dialog: {
    width: 400
  },
}));

const BoKe = () => {
  const classes = useStyles();
  const [list, setList] = useState([])
  const [keyword, setKeyword] = useState('')
  const [cate, setCate] = useState('')
  const [cateObj, setCateObj] = useState({})
  const [rowCount, setRowCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [dialog, setDialog] = useState({
    open: false
  })
  const context = useContext(appContext)

  const goReload = () => {
    setReload(false)
    setTimeout(() => {
      setReload(true)
    })
  }

  useEffect(() => {
    request({
      url: '/ajax/boke/catelist',
      method: 'get'
  }).then(res => {
      if (res.data) {
        console.log(res.data)
        res.data.forEach(item => {
          cateObj[item.id] = item.cate_name
        })
        setCateObj(cateObj)
      }
  })
  }, [])

  useEffect(() => {
    setLoading(true)
    request({
      url: '/ajax/boke/list',
      method: 'get',
      data: {
        limit: 20,
        offset: offset,
        kw: keyword,
        cate_id: cate
      }
    }).then(res => {
      if (offset === 0) {
        goReload()
      }
      setList(res.data.list.map((item, index) => {
        item.bid = item.id
        item.id = index
        return item
      }))
      
      setRowCount(res.data.total)
      setLoading(false)

    })
  }, [offset, keyword, cate])

  const updateState = (row, index, newState) => {
    request({
      url: '/ajax/boke/modifystate',
      method: 'post',
      data: {
        id: row.bid,
        state: newState
      }
    }).then(res => {
      let newRows = list.concat()
      newRows[index].state = newState
      setList(newRows)
      context.showToast('操作成功')
    })
  }

  const remove = () => {
    let row = dialog.row
    let index = dialog.index
    request({
      url: '/ajax/boke/del',
      method: 'post',
      data: {
        id: row.bid
      }
    }).then(res => {
      let newRows = list.concat()
      newRows.splice(index, 1)
      setList(newRows)
      closeDialog()
      context.showToast('删除成功')
    })
  }

  const closeDialog = () => {
    setDialog({
      open: false
    })
  }

  return <Page
    className={classes.root}
    title="播客"
  >
    <Container maxWidth={false}>
      <Grid container className={classes.toolbar} justify="space-between">
        <Grid item xs={4}>
          <TextField
            label="搜索"
            fullWidth
            variant="outlined"
            color="primary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            placeholder="输入关键字，按回车搜索"
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                setKeyword(e.target.value)
                setOffset(0)
              }

              if (e.target.value === '') {
                setKeyword('')
                setOffset(0)
              }
            }}
          ></TextField>
        </Grid>
        <Grid item container xs={4} justify="flex-end">
          <Grid item>
            <Button variant="contained" color="primary" href="/app/boke/add" target="_blank">添加播客</Button>
          </Grid>
        </Grid>
      </Grid>
      {reload && <DataGrid
        components={{
          footer: Pager
        }}
        disableSelectionOnClick={true}
        className={classes.table}
        autoHeight
        rowHeight={80}
        rows={list}
        columns={[{
          field: 'bid',
          headerName: 'id',
          width: 70
        }, {
          field: 'title',
          headerName: '标题',
          width: 360,
          renderCell: (params) => {
            return <Grid container direction="row" spacing={0} justify="flex-start">
              <Grid item>
                <Tooltip title="播放">
                  <IconButton color="primary" href={`//video.lmtest.cn/${params.row.media_url}`} target="_blank" >
                    <PlayCircleFilledIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <p style={{
                  width: 280, 
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  wordBreak: 'break-all',
                  textOverflow: 'ellipsis'
                }}>{params.row.title}</p>
              </Grid>
            </Grid>
          }
        }, 
        // {
        //   field: 'author',
        //   headerName: '作者',
        //   width: 100,
        // }, 
        {
          field: 'cate_name',
          headerName: '分类',
          width: 150,
          sortable: false,
          renderHeader: (params) => {
            return <FormControl>
            <Select
              className={classes.select}
              native
              value={cate}
              color="primary"
              variant="standard"
              onChange={(e) => {
                setCate(e.target.value)
              }}
              inputProps={{
                style: {
                  height: 40,
                  fontSize: '0.875em',
                  fontWeight: 500
                }
              }}
            >
              <option value="">全部分类</option>
              {Object.keys(cateObj).map(key => {
                return <option value={key}>{cateObj[key]}</option>
              })}
            </Select>
          </FormControl>
          }
        }, {
          field: 'cover',
          headerName: '封图',
          width: 72,
          renderCell: (params) => {
            return <img style={{width: 36, height: 36}} src={`//pic.lmtest.cn/${params.row.img}?imageView2/1/w/${36}/h/${36}`} />
          }
        },  {
          field: 'pub_date',
          headerName: '发布时间',
          width: 220
        }, 
        // {
        //   field: 'duration',
        //   headerName: '音频时长',
        //   width: 100,
        // }, 
        {
          field: 'state',
          headerName: '状态',
          width: 80,
          valueGetter: (params) => {
            return params.row.state === '0' ? '草稿' : (params.row.state === '1' ? '线上' : '删除')
          }
        },
        {
          field: 'play',
          headerName: '操作',
          width: 250,
          renderCell: (params) => {
            return <Grid container direction="row" spacing={1}>
              {params.row.state === '0' && 
                <Grid item>
                  <Button size="small" 
                color="primary" 
                variant="contained"
                onClick={() => {
                  updateState(params.row, params.rowIndex, '1')
                }}>上线</Button>
              </Grid>}
              {params.row.state === '1' && 
                <Grid item>
                  <Button 
                    size="small" 
                    color="primary" 
                    variant="outlined"
                    onClick={() => {
                      updateState(params.row, params.rowIndex, '0')
                    }}
                    >
                  下线
                </Button>
              </Grid>}
              <Grid item>
                <Button 
                  disabled={params.row.allow_edit === 0}
                  size="small" 
                  variant="contained" 
                  color="primary"
                  href={`/app/boke/edit/${params.row.bid}`}
                  target="_blank">编辑</Button>
              </Grid>
              <Grid item>
                <Button size="small" 
                  variant="outlined" 
                  color="default"
                  onClick={() => {
                    setDialog({
                      open: true,
                      row: params.row,
                      index: params.rowIndex
                    })
                  }}
                  >删除</Button>
              </Grid>
            </Grid>
            
          }

        }]}
        pagination
        paginationMode="server"
        pageSize={20} 
        rowCount={rowCount}
        onPageChange={(params) => {
          setOffset((params.page - 1) * 20)
        }}
        loading={loading}
        ></DataGrid>}
    </Container>

      <Dialog
        open={dialog.open}
        onClose={closeDialog}
      >
        <DialogTitle>提示</DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            确定要删除吗？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="default">
            否
          </Button>
          <Button onClick={() => {
            remove()
          }} color="primary" autoFocus variant="contained">
            是
          </Button>
        </DialogActions>
      </Dialog>

  </Page>
}

export default BoKe