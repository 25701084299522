import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Typography,
  Button,
  TextField
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import request from 'src/utils/request'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  Typography: {
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const PopAd = (props) => {
  const classes = useStyles();
  const [list, setList] = useState()

  useEffect(() => {
    request({
      url: '/ajax/ad/list',
      method: 'get',
      data: {
        type: props.type,
      }
    }).then(res => {
      setList(res.data.list)
    })
  }, [props.type])

  const setState = (item, index) => {
    let newState = item.state === 1 ? 0 : 1
    request({
      url: '/ajax/ad/set_state',
      method: 'post',
      data: {
        id: item.id,
        state: newState
      }
    }).then(res => {
      let newlist = list.concat()
      newlist[index].state = newState
      setList(newlist)
    })
  }

  const columns = [
    {
      field: 'title',
      headerName: '广告名',
      width: 200,
    }, 
    {
      field: 'cover',
      headerName: '广告图',
      width: 200,
      renderCell: (params) => {
        return <img src={`${params.row.cover_url}?imageView2/1/w/${220}/h/${48}`} />
      }
    }, {
      field: 'begintime',
      headerName: '开始时间',
      type: 'dateTime',
      width: 200,
      valueGetter: (params) => {
        return new Date(params.row.begin_time * 1000)
      }
    }, {
      field: 'endTime',
      headerName: '结束时间',
      type: 'dateTime',
      width: 200,
      valueGetter: (params) => {
        return new Date(params.row.end_time * 1000)
      }
    }, 
    {
      field: 'state',
      headerName: '状态',
      width: 80,
      valueGetter: (params) => {
        switch(params.value) {
          case 0: 
            return '禁用'
          case 1: 
            return '启用'
          default: 
            return ''
        }
      }
    }, {
      field: 'action',
      headerName: '操作',
      width: 260,
      renderCell: (params) => {
        return <Typography className={classes.Typography}>
        {params.row.state === 0 && <Button 
          variant="contained" 
          size="small" 
          color="primary"
          onClick={() => {
            setState(params.row, params.rowIndex)
          }}
        >
          启用
        </Button>}
        {params.row.state === 1 && <Button 
          variant="outlined" 
          size="small" 
          color="primary"
          onClick={() => {
            setState(params.row, params.rowIndex)
          }}
        >
          禁用
        </Button>}
        <Button 
          variant="contained" 
          size="small" 
          color="primary"
          onClick={() => {
            if (props.onEdit) {
              props.onEdit(params.row)
            }
          }}
        >
          编辑
        </Button>
        <Button 
          variant="outlined" 
          size="small" 
          color="default"
          onClick={() => {
            if (props.onRemove) {
              props.onRemove(params.row, params.rowIndex)
            }
          }}
          >
          删除
        </Button>
      </Typography>
      }
    }
  ]

  if (props.type === 'FEED') {
    columns.unshift({
      field: 'position',
      headerName: '位置',
      width: 72
    })
  }

  return (
      <>
      {list && <DataGrid
      disableSelectionOnClick={true}
      className={classes.root}
      autoHeight
      rows={list}
      columns={columns}
      page={1}
      pageSize={10}
    ></DataGrid>
  }
    </>
    );
};

export default PopAd;
