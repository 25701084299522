import React, { useState, useEffect, useContext } from 'react'
import { 
  makeStyles,
  Container,
  Grid,
  Button,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core"
import Page from 'src/components/Page'
import request from 'src/utils/request'
import { DataGrid } from '@material-ui/data-grid'
import { dateFormat } from 'src/utils/utils'
import appContext from 'src/AppContext'



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff'
  },
  dialog: {
    width: 400
  },
}));

const Activities = () => {
  const classes = useStyles()
  const [list, setList] = useState()
  const context = useContext(appContext)
  const [dialog, setDialog] = useState({
    open: false
  })


  useEffect(() => {
    request({
      url: '/ajax/lottery/list',
      method: 'get',
    }).then(res => {
      setList(res.data.list.map((item, index) => {
        item.aid = item.id
        item.id = index
        return item
      }))
    })
  }, [])

  const updateState = (row, index, newState) => {
    request({
      url: '/ajax/lottery/set_state',
      method: 'post',
      data: {
        id: row.aid,
        state: newState
      }
    }).then(res => {
      let newRows = list.concat()
      newRows[index].state = newState
      setList(newRows)
      context.showToast('操作成功')
    })
  }

  const updateEntry = (row, value, index) => {
    request({
      url: '/ajax/lottery/set_entry_state',
      method: 'post',
      data: {
        id: row.aid,
        entry_state: value
      }
    }).then(res => {
      let newRows = list.concat()
      if (value === 1) {
        newRows.forEach((item, i) => {
          if (index === i) {
            newRows[index].entry_state = value
          } else {
            newRows[i].entry_state = 0
          }
        })
      } else {
        newRows[index].entry_state = value
      }
      setList(newRows)
      context.showToast('操作成功')
    })
  }

  const closeDialog = () => {
    setDialog({
      open: false
    })
  }

  const remove = () => {
    let row = dialog.row
    let index = dialog.index
    request({
      url: '/ajax/lottery/del',
      method: 'post',
      data: {
        id: row.aid
      }
    }).then(res => {
      let newRows = list.concat()
      newRows.splice(index, 1)
      setList(newRows)
      closeDialog()
      context.showToast('删除成功')
    })
  }

 

  return <Page 
    className={classes.root}
    title="活动">
      <Container maxWidth={false}>
        <Grid container direction="column" spacing={2}>
          <Grid item container direction="row" justify="space-between">
            <Grid item>
            
            </Grid>
            <Grid item>
              <Button 
                size="medium" 
                color="primary" 
                variant="contained"
                href={`/app/activities/add`}>新增活动</Button>
            </Grid>
          </Grid>
          <Grid item>
          {list && <DataGrid
            disableSelectionOnClick={true}
            className={classes.table}
            autoHeight
            rows={list}
            columns={[
              {
                field: 'aid',
                headerName: 'id',
                width: 70
              }, {
                field: 'name',
                headerName: '活动名称',
                width: 120
              }, 
              {
                field: 'put_begin_time',
                headerName: '投币开始时间',
                width: 200,
                valueGetter: (params) => {
                  return params.row.put_begin_time ? dateFormat(new Date(params.row.put_begin_time * 1000)): '-'
                }
              }, 
              {
                field: 'put_end_time',
                headerName: '投币结束时间',
                width: 200,
                valueGetter: (params) => {
                  return params.row.put_end_time ? dateFormat(new Date(params.row.put_end_time * 1000)) : '-'
                }
              },
              {
                field: 'user_cnt',
                headerName: '参与人数',
                width: 90,
              },
              {
                field: 'entry_state',
                headerName: '入口开关',
                width: 120,
                renderCell: (params) => {
                  return <Switch checked={params.row.entry_state === 1} onChange={(e) => {
                    let checked = e.target.checked
                    let value = checked ? 1 : 0
                    updateEntry(params.row, value, params.rowIndex)
                  }}></Switch>
                }
              },
              
              {
                field: 'stage',
                headerName: '当前阶段',
                width: 100,
                renderCell: (params) => {
                  let row = params.row
                  let arr = ['已开奖', '未开奖', '未生成券码']
                  let status = row.draw_time ? 0 
                    : row.ticket_cnt ? 1
                    : 2
                  return <p>
                    {arr[status]} 
                  </p>
                } 
                
              },
              {
                field: 'state',
                headerName: '状态',
                width: 70,
                valueGetter: (params) => {
                  return params.row.state === 1 ? '启用': '禁用'
                }
              },
              {
                field: 'action',
                headerName: '操作',
                width: 250,
                renderCell: (params) => {
                    return <Grid container direction="row" spacing={1}>
                      <Grid item>
                        {params.row.state === 0 && <Button 
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            updateState(params.row, params.rowIndex, 1)
                          }}>上线</Button>} 
                        {params.row.state === 1 && <Button 
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            updateState(params.row, params.rowIndex, 0)
                          }}>下线</Button>}
                      </Grid>
                      <Grid item>
                          <Button size="small" 
                            color="primary" 
                            variant="contained" href={`/app/activities/edit/${params.row.aid}`}>
                              设置
                            </Button>
                      </Grid>
                      <Grid item>
                          <Button size="small" 
                            color="default" 
                            variant="outlined"
                            onClick={() => {
                              setDialog({
                                open: true,
                                row: params.row,
                                index: params.rowIndex
                              })
                            }}>
                              删除
                            </Button>
                      </Grid>
                    </Grid>
                }
              }
            ]}
            hideFooter
            ></DataGrid>}
          </Grid>
        </Grid>
        
       
      </Container>

      <Dialog
        open={dialog.open}
        onClose={closeDialog}
      >
        <DialogTitle>提示</DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            确定要删除吗？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="default">
            否
          </Button>
          <Button onClick={() => {
            remove()
          }} color="primary" autoFocus variant="contained">
            是
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
}

export default Activities