import React, { useEffect, useState } from 'react';
import { 
    makeStyles,
    Paper,
    Button,
    Tooltip,
    IconButton,
    Grid,
    FormHelperText,
    CircularProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CachedIcon from '@material-ui/icons/Cached';
import * as qiniu from 'qiniu-js'
import request from 'src/utils/request'


const UploadImage = (props) => {

  const width = props.width || 200
  const height = props.height || 80
  const text = props.text || '上传图片'
  const type = props.type || 'image' //'image' || 'audio'
  const disabled = props.disabled || false
  const useStyles = makeStyles((theme) => ({
    root: {
      width: width,
      height: height,
      marginTop: 12,
      marginBottom: 12,
      position: 'relative',
      border: '1px dashed #999999',
    },
    imageWrap : {
      width: width
    },
    button: {
      width: width,
      height: height
    },
    iconWrap: {
      position: 'absolute',
      width: 48,
      right: -48,
      top: 0
    },
  }));
  
  
  const classes = useStyles();
  const fileInput = React.createRef()
  const [image, setImage] = useState(props.image)
  const [token, setToken] = useState()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    request({
      url: '/ajax/qiniu/token',
      method: 'get',
      data: {
        type: type === 'audio' ? 'video' : '',
        prefix: props.prefix || 'other/'
      }
    }).then(res => {
      if (res.data) {
        setToken(res.data.token)
      }
    }, err => {
      
    })
  }, [])

  useEffect(() => {
    setError(props.error)

  }, [props.error])

  const onImageChange = (file) => {
    if (file) {
      setLoading(true)
      qiniu.upload(
        file, 
        null, 
        token
      ).subscribe({
        error(err) {
          setLoading(false)
        },
        complete(res) {
          if (res.key) {
            setImage(res.key)
            if (props.onChange) {
              props.onChange(res.key)
            }
          }
          setLoading(false)
        }
      })
    }
    
  }

  return <Paper className={classes.root} elevation={0}>
    
    {image && <>
      {type === 'image' && <div className={classes.imageWrap}>
        <img src={`//pic.lmtest.cn/${image}?imageView2/1/w/${width}/h/${height}`} />
      </div>}

      {type === 'audio' && <Button 
        className={classes.button}
        color="primary" 
        fullWidth
        href={`//video.lmtest.cn/${image}`}
        target="_blank"
        >点击试听</Button>
      }
    </>
    }
      
    {image && !disabled &&
      <Grid className={classes.iconWrap} container justify="space-around" direction="column">
        <Tooltip title="更换图片">
          <IconButton color="primary" onClick={() => {
            fileInput.current.click()
          }} aria-label="delete">
            <CachedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="删除图片">
          <IconButton onClick={() => {
            setImage(undefined)
            if (props.onChange) {
              props.onChange(undefined)
            }
          }} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    }
    
    {!image && <>
      {!loading && <Button 
        className={classes.button}
        color="primary" 
        fullWidth
        onClick={() => {
          fileInput.current.click()
        }}>{ text}</Button>}
      {loading && <CircularProgress color="primary" style={{
        position: "absolute",
        left: '50%',
        top: '50%',
        marginLeft: -20,
        marginTop: -20
      }}></CircularProgress>}  
    </>
      
      }
    {error && !loading && !image && <FormHelperText error>{`请${text}`}</FormHelperText>}
    <input 
      ref={fileInput} 
      type="file" 
      accept={`${type}/*`}
      onChange={(e) => {
        onImageChange(e.target.files[0])
      }}
      hidden ></input>
  </Paper>
}

export default UploadImage