import React from 'react'
import { Navigate } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import MainLayout from 'src/layouts/MainLayout'
import DashboardView from 'src/views/dashboard'
import LoginView from 'src/views/auth/LoginView'
import NotFoundView from 'src/views/errors/NotFoundView'

import ExampleView from 'src/views/example'
import ExampleTableView from 'src/views/example/table'
import ExampleEditView from 'src/views/example/edit'
import ExampleQuillView from 'src/views/example/quill'
import ExampleTabsView from 'src/views/example/tabs'

import CategoriesView from 'src/views/categories'
import ArticlesView from 'src/views/articles'
import ArticlesEditView from 'src/views/articles/edit'
import AdView from 'src/views/ad'
import BoKeView from 'src/views/boke'
import BokeEditView from 'src/views/boke/edit'
import CustomersView from 'src/views/customers'
import SettingsView from 'src/views/settings'
import ActivitiesView from 'src/views/activities'
import ActivitiesEditView from 'src/views/activities/edit'
import InsureProductView from 'src/views/insure/product'
import InsureProductEditView from 'src/views/insure/product/edit'
import InsureRankView from 'src/views/insure/rank'
import InsureRankEditView from 'src/views/insure/rank/edit'


const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <DashboardView /> },
      { path: 'articles', element: <ArticlesView /> },
      { path: 'articles/add', element: <ArticlesEditView /> },
      { path: 'articles/edit/:id', element: <ArticlesEditView /> },
      { path: 'categories', element: <CategoriesView /> },
      { path: 'ad', element: <AdView /> },
      { path: 'boke', element: <BoKeView /> },
      { path: 'boke/add', element: <BokeEditView /> },
      { path: 'boke/edit/:id', element: <BokeEditView /> },
      { path: 'customers', element: <CustomersView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'activities', element: <ActivitiesView /> },
      { path: 'activities/add', element: <ActivitiesEditView /> },
      { path: 'activities/edit/:id', element: <ActivitiesEditView /> },
      { path: 'insure/product', element: <InsureProductView /> },
      { path: 'insure/product/add', element: <InsureProductEditView /> },
      { path: 'insure/product/edit/:id', element: <InsureProductEditView /> },
      { path: 'insure/rank', element: <InsureRankView /> },
      { path: 'insure/rank/edit/:id', element: <InsureRankEditView /> },

      { path: 'example', element: <ExampleView /> },
      { path: 'example/table', element: <ExampleTableView /> },
      { path: 'example/add', element: <ExampleEditView /> },
      { path: 'example/edit/:id', element: <ExampleEditView /> },
      { path: 'example/quill', element: <ExampleQuillView /> },
      { path: 'example/tabs', element: <ExampleTabsView /> },

      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
