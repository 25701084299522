import React, { useEffect, useState, useContext } from "react"
import {
    makeStyles,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Input,
    Checkbox,
    Tooltip,
    IconButton,
    Container,
    FormHelperText
  } from '@material-ui/core'
import { useParams } from "react-router-dom";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

  const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 400
    },
    tableEdit: {
        minWidth: 500
    },
    tablecell: {
        padding: 8,
        maxWidth: 56,
    },
    tablecellForName: {
        maxWidth: 120
    },
    editTableCell: {
        padding: 4,
        minWidth: 64,
        maxWidth: 92
    },
    checkbox: {
        padding: 4
    },
    inputbase: {
        fontSize: '0.875rem',
        // border: '1px solid rgba(0, 0, 0, .1)',
        // borderRadius: 4,
        paddingLeft: 4
    },
    
  }));

const fixedCellWidth = [60, 180, 100, 72]

const Rank = (props) => {
    const classes = useStyles()
    let { id } = useParams();

    // const [data, setData] = useState([
	// 	['排名', '名称', '优选','总分', '车内空气质量', '驾驶空间', '副驾空间', '后排空间'],
	// 	['1', '奥迪 Q5L', false, 9.56, 9.73, 9.94, 8.28, 9.95],
	// 	['2', '奔驰 GLC', true, 9.06, 9.49, 7.97, 8.29, 9.63],
	// 	['3', '凯迪拉克 XT5',false, 9.01, 9.04, 9.29, 7.63, 8.95],
	// 	['4', '沃尔沃 XC60',false, 8.64, 8.42, 8.63, 7.96, 9.96]
    // ])
    const [data, setData] = useState([
		['排名', '名称', '优选', '总分'],
    ])
    const [dataInEdit, setDataInEdit] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [errorRecorder, setErrorRecorder] = useState({})
    const [reload, setReload] = useState(false)

    useEffect(() => {
        if (props.originData && props.originData.rank_data && props.originData.rank_data.length !== 0) {
            setData(props.originData.rank_data)
        } else {
            toEdit()
        }
    }, [props.originData])

    const goReload = () => {
        setReload(true)
        setTimeout(() => {
            setReload(false)
        })
    }

    const addCol = () => {
        let cdata = dataInEdit.concat()
        cdata.forEach(item => {
            item.push('')
        })
        setDataInEdit(cdata)
    }

    const removeCol = (colIndex) => {
        let cdata = dataInEdit.concat()
        cdata.forEach(item => {
            item.splice(colIndex, 1)
        })
        setDataInEdit(cdata)
        goReload()
    }

    const addRow = () => {
        let cdata = dataInEdit.concat()
        let newRow = []
        cdata[0].forEach((item, colIndex) => {
            if (colIndex === 0) {
                newRow.push(cdata.length)
            } else if (colIndex === 2) {
                newRow.push(false)
            } else {
                newRow.push('')
            }
        })
        cdata.push(newRow)
        setDataInEdit(cdata)
        goReload()
    }

    const removeRow = (rowIndex) => {
        let cdata = dataInEdit.concat()
        cdata.splice(rowIndex, 1)
        setDataInEdit(cdata)
        goReload()
    }

    const changeEditData = (value, rowIndex, colIndex) => {
        let cdata = dataInEdit.concat()
        cdata[rowIndex][colIndex] = value
        setDataInEdit(cdata)
    }

    const initEditData = () => {
        let cdata = data.concat()
        setDataInEdit(cdata)
    }

    const toEdit = () => {
        initEditData()
        setIsEdit(true)
    }

    const transformNumber = () => {
        let copy = dataInEdit.concat()
        copy.forEach((row, rowIndex) => {
            if (rowIndex > 0) {
                row.forEach((item, colIndex) => {
                    if (colIndex > 2) {
                        if (typeof item === 'string') {
                            let count = item.replace(/^\d+(\.)*/, '').length
                            copy[rowIndex][colIndex] = parseFloat(item).toFixed(count)
                        } else {
                            copy[rowIndex][colIndex] = item
                        }
                        
                    }
                })
            }
        })

        return copy

    }

    const validation = () => {
        let flag = true
        let obj = {}
        if (dataInEdit && dataInEdit.length > 0) {
            dataInEdit.forEach((row, rowIndex) => {
                row.forEach((cell, colIndex) => {
                    if (rowIndex === 0) {
                        if (colIndex > 3) {
                            if (!cell.trim() || cell.trim() === '') {
                                obj[`${rowIndex}${colIndex}`] = true
                                flag = false
                            }
                        }
                    } else {
                        let value = cell.toString()
                        if (colIndex === 0) {
                            if (!value.trim().match(/^[1-9]\d*/)) {
                                obj[`${rowIndex}${colIndex}`] = true
                                flag = false
                            }
                        } else if (colIndex === 1) {
                            if (!value.trim() || value.trim() === '') {
                                obj[`${rowIndex}${colIndex}`] = true
                                flag = false
                            }
                        } else if (colIndex > 2) {
                            if (!value.trim().match(/^([1-9](\.\d{1,})?|10)$/)) {
                                obj[`${rowIndex}${colIndex}`] = true
                                flag = false
                            }
                        }
                        
                    }
                    
                })
            })
        }
        setErrorRecorder(obj)
        return flag

    }

    const save = () => {
        if (props.onSave) {
            // dataInEdit[0][3] = '总分'
            // dataInEdit = [
            //     ['排名', '名称', '优选','总分', '车内空气质量', '驾驶空间', '副驾空间', '后排空间'],
            //     ['1', '奥迪 Q5L', false, 9.56, 9.73, 9.94, 8.28, 9.95],
            //     ['2', '奔驰 GLC', true, 9.06, 9.49, 7.97, 8.29, 9.63],
            //     ['3', '凯迪拉克 XT5',false, 9.01, 9.04, 9.29, 7.63, 8.95],
            //     ['4', '沃尔沃 XC60',false, 8.64, 8.42, 8.63, 7.96, 9.96]
            // ]
            if (!validation()) {
                return
            }
            props.onSave(transformNumber())
            setIsEdit(false)
            setDataInEdit(null)
        }
    }

    const cancel = () => {
        setIsEdit(false)
        setDataInEdit(null)
    }

    return <Container className={classes.container}>
    <Grid  container spacing={2}>
        <Grid container item direction="row" spacing={2}>
           {!isEdit && <Grid item><Button 
                variant="contained" 
                color="primary"
                onClick={toEdit}>编辑排名</Button></Grid>}
            {isEdit && <Grid item><Button 
                variant="contained" 
                color="primary"
                onClick={save}>保存</Button></Grid>}
            {isEdit && <Grid item><Button
                variant="outlined" 
                color="default"
                onClick={cancel}>取消</Button></Grid>}
        </Grid>
        <Grid item>
            {JSON.stringify(errorRecorder) !== "{}" && 
                <FormHelperText error>
                    请检查：1. 所填项不能为空；2. 排名必须填整数且大于0； 3. 分数大于1且小于10</FormHelperText>}
        </Grid>
        <Grid container item direction="row">
            <Grid item>
                <TableContainer>
                    {!isEdit && (data.length > 1 || data[0].length > 5) && <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {data[0].map((item, index) => {
                                    return <TableCell 
                                        align="left" 
                                        width={index === 1 ? 160: 80}
                                        className={classes.tablecell}>
                                        {item}
                                    </TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.slice(1).map((row, rowIndex) => {
                                return <TableRow key={`tableRow${rowIndex}`}>
                                    {row.map((item, colIndex) => {
                                        return <TableCell 
                                            align="left" 
                                            width={colIndex === 1 ? 160: 80}
                                            className={classes.tablecell}>
                                            {colIndex === 2 ? (item ? `是` : `否`)  : item}
                                        </TableCell>
                                    })}
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>}
                    {isEdit && <Table className={classes.tableEdit}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.editTableCell}></TableCell>
                                {dataInEdit[0].map((item, colIndex) => {
                                    return <TableCell className={classes.editTableCell}>
                                        {colIndex > 3 && <Tooltip title="删除这一列">
                                        <IconButton onClick={() => {
                                            removeCol(colIndex)
                                        }}>
                                            <RemoveCircleIcon />
                                        </IconButton>
                                        </Tooltip>}
                                    </TableCell>
                                })}
                                <TableCell className={classes.editTableCell}>
                                    <Tooltip title="新增一列">
                                        <IconButton color="primary">
                                            <AddCircleIcon onClick={() => {
                                                addCol()
                                            }} />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.editTableCell}></TableCell>
                                {dataInEdit[0].map((item, colIndex) => {
                                   return <TableCell 
                                    style={{width: fixedCellWidth[colIndex] || 'auto'}}
                                    className={classes.editTableCell}>
                                       {colIndex <= 3 && item}
                                       {colIndex > 3 && !reload && <Input 
                                        className={classes.inputbase}
                                        defaultValue={item}
                                        error={errorRecorder[`0${colIndex}`]}
                                        onChange={(e) => {
                                            changeEditData(e.target.value, 0, colIndex)
                                        }}></Input>}
                                   </TableCell> 
                                })}
                                <TableCell className={classes.editTableCell}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataInEdit.slice(1).map((row, rowIndex) => {
                                return <TableRow>
                                    <TableCell className={classes.editTableCell}>
                                        <Tooltip title="删除这一行">
                                            <IconButton onClick={() => {
                                                removeRow(rowIndex + 1)
                                            }}>
                                                <RemoveCircleIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    {row.map((item, colIndex) => {
                                        return <TableCell 
                                        style={{width: fixedCellWidth[colIndex] || 'auto'}}
                                        className={classes.editTableCell}>
                                            {colIndex === 2 && <Checkbox
                                                className={classes.checkbox} 
                                                checked={item}
                                                onChange={(e) => {
                                                    changeEditData(e.target.checked, rowIndex + 1, colIndex)
                                                }}></Checkbox>} 
                                            {colIndex !== 2 && !reload && <Input
                                                className={classes.inputbase}
                                                defaultValue={item}
                                                error={errorRecorder[`${rowIndex + 1}${colIndex}`]}
                                                onChange={(e) => {
                                                    changeEditData(e.target.value, rowIndex + 1, colIndex)
                                                }}
                                                ></Input>}
                                        </TableCell>
                                    })}
                                    <TableCell></TableCell>
                                </TableRow>
                            })}
                            <TableRow>
                                <TableCell className={classes.editTableCell}>
                                    <Tooltip title="新增一行">
                                        <IconButton color="primary" onClick={() => {
                                            addRow()
                                        }} >
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                {dataInEdit[0].map(item => {
                                    return <TableCell className={classes.editTableCell}></TableCell>
                                })}
                                <TableCell className={classes.editTableCell}></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>}
                </TableContainer>
            </Grid>
            <Grid item></Grid>
         </Grid>
    </Grid>
    </Container>
    
}

export default Rank