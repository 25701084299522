import React, { useState, useEffect } from 'react';
import {
  Container,
  makeStyles,
  Grid,
  Paper,
  Tooltip,
  IconButton,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import request from 'src/utils/request';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  container: {
    backgroundColor: theme.palette.background.default,
    padding: 20
  },
  grid: {
    marginTop: 20,
  },
  paper: {
    height: 90,
    width: 330,
    padding: 16,
    border: '2px dashed transparent'

  },
  dialog: {
    width: 400
  },
}));

const Categories = () => {
  const classes = useStyles();
  const [list, setList] = useState([])
  const [edit, setEdit] = useState(-1)
  const [open, setOpen] = useState(false)
  const [pendingRemove, setPendingRemove] = useState()
  const [editError, setEditError] = useState()
  const [newone, setNewone] = useState(-1)
  const [drag, setDrag] = useState()
  const [dragEnter, setDragEnter] = useState()
  const [nameInput, setNameInput] = useState()

  useEffect(() => {
    request({
      url: '/ajax/tag/list',
      method: 'get'
    }).then(res => {
      if (res.data) {
        setList(res.data.list)
      }
    })
  }, [])

  const save = (id, name, index) => {
    if (name) {
      request({
        url: '/ajax/tag/save',
        method: 'post',
        data: {
          id,
          name
        }
      }).then(res => {
        let newlist = list.concat()
        newlist[index].name = name
        newlist[index].id = res.data.id
        setList(newlist)
        setEdit(-1)
        setNewone(-1)
      })
    } else {

    }
    
  }

  const remove = () => {
    console.log(pendingRemove)
    request({
      url: '/ajax/tag/del',
      method: 'post',
      data: {
        id: pendingRemove.item.id
      }
    }).then(res => {
      let newlist = list.concat()
      newlist.splice(pendingRemove.index, 1)
      setList(newlist)
      setPendingRemove(undefined)
      setOpen(false)
      setNewone(-1)
    })
  }

  const sort = (newlist) => {
    request({
      url: '/ajax/tag/sort',
      method: 'post',
      data: {
        ids: newlist.map(item => {
          return item.id
        }).join(',')
      }
    }).then(res => {

    })
  }

  const create = () => {
    if (newone === -1) {
      setNameInput(React.createRef())
      let newlist = list.concat({
        id: 0,
        name: ''
      })
      setList(newlist)
      setEdit(newlist.length -1)
      setNewone(newlist.length -1)
    }
  }

  const switchItem = () => {
    if (drag !==undefined && drag.move !==undefined && drag.moveto !== undefined) {
      let newlist = list.concat()
      newlist[drag.move] = newlist.splice(drag.moveto, 1, newlist[drag.move])[0]
      setList(newlist)
      sort(newlist)
    }
  }

  return (
    <Page
      className={classes.root}
      title="分类管理"
    >
      <Container className={classes.container} maxWidth={false} >
        {/* <Typography variant="h2" gutterBottom>文章分类管理</Typography> */}
        <Typography variant="subtitle1" gutterBottom>Tips: 分类拖动可排序</Typography>
        <Grid container direction="column">
          {list.map((item, index) => {
            return <Grid className={classes.grid} item key={`cate${index}`}>
                <Paper 
                  className={`${classes.paper}`}
                  style={{
                    borderColor: (drag && dragEnter === index) ? '#cccccc' : ''
                  }}
                  elevation={3}
                  draggable
                  onDragStart={() => {
                    setDrag({
                      move: index
                    })
                  }}
                  onDragEnter={() => {
                    setDragEnter(index)
                  }}
                  onDragOver={(e) => {
                    e.preventDefault()
                  }}
                  onDrop={() => {
                    drag.moveto = index
                    setDrag(drag)
                    switchItem()
                  }}
                  onDragEnd={() => {
                    setDrag(undefined)
                  }}
                  >
                    <Grid container justify="space-between">
                      {edit !== index && <Grid item>分类{index+1} <b>{item.name}</b></Grid>}
                      {edit === index && <Grid item>
                          <TextField 
                            size="small"
                            defaultValue={list[index].name}
                            onKeyUp={(e) => {
                              if (e.key === 'Enter') {
                                if (e.target.value) {
                                  save(item.id, e.target.value, index)
                                } else {
                                  setEditError({
                                    index,
                                    text: '分类名不能为空'
                                  })
                                }
                              }
                            }}
                            inputRef={edit === index ? nameInput : undefined}
                            focused={edit === index}
                            error={editError && editError.index === index}
                            helperText={editError && editError.index === index && editError.text}
                            variant="outlined"
                             />
                        </Grid>}
                      <Grid container item xs={4} >
                        {edit !== index && <Tooltip title="修改分类名">
                          <IconButton color="primary" onClick={() => {
                            setEdit(index)
                            setNameInput(React.createRef())
                          }} aria-label="edit">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>}
                        {edit === index && <Tooltip title="提交修改">
                          <IconButton color="primary" onClick={() => {
                            if (nameInput.current.value) {
                              save(item.id, nameInput.current.value, index)
                            } else {
                              setEditError({
                                index,
                                text: '分类名不能为空'
                              })
                            }
                          }} aria-label="edit">
                            <DoneIcon />
                          </IconButton>
                        </Tooltip>}
                        <Tooltip title="删除分类">
                          <IconButton onClick={() => {
                            if (newone !== -1 && newone === index) {
                              let newlist = list.concat()
                              newlist.pop()
                              setList(newlist)
                              setNewone(-1)
                            } else {
                              setOpen(true)
                              setPendingRemove({
                                item,
                                index
                              })
                            }
                            
                          }} aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                </Paper>
              </Grid>
          })}
          <Grid className={classes.grid} item>
            <Button 
              color="primary" 
              variant="contained"
              onClick={() => {
                create()
              }}
            >新增分类</Button>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <DialogTitle>提示</DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            确定要删除吗？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpen(false)
            setPendingRemove(undefined)
          }} color="default">
            否
          </Button>
          <Button onClick={() => {
            remove()
          }} color="primary" autoFocus variant="contained">
            是
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default Categories;
