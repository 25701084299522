import React, { useState, useContext, useEffect } from 'react'
import {
    InputLabel,
    Input,
    InputAdornment,
    FormControl,
    Tooltip,
    IconButton,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';


  const SearchSelect = ({
      label,
      value,
      list,
      onKeyUp,
      onChange
  }) => {

        const [keyword, setKeyword] = useState('')
        const [open, setOpen] = useState(false)


        return <FormControl>
            <Grid style={{position: "relative"}}>

            <InputLabel>{label}</InputLabel>
                <Input 
                    value={value ? value.name : keyword}
                    readOnly={Boolean(value)}
                    onKeyUp={(e) => {
                        setOpen(true)
                        onKeyUp(e.target.value)
                    }}
                    onChange={(e) => {
                        setKeyword(e.target.value)
                    }}
                    endAdornment={
                        <>
                            {Boolean(value) && <InputAdornment position="end">
                                <Tooltip title="删除重新选择">
                                <IconButton onClick={() => {
                                    onChange(null)
                                    setKeyword('')
                                }} aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }</>
                    }
                />
                {open && <Paper style={{position: "absolute", width: '100%', height: 300, backgroundColor: "#fff", zIndex: 2, overflowY: "scroll"}} elevation={3}>
                    <List >
                        {list.map(item => (
                            <ListItem button>
                                <ListItemText primary={item.name} onClick={() => {
                                    onChange(item)
                                    setOpen(false)
                                }}></ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Paper>}
                

            </Grid>
            
            
        </FormControl>
  }

export default SearchSelect
