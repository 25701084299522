import React, { useContext,useEffect,useState } from 'react';
import {
  Container,
  makeStyles,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import Page from 'src/components/Page';
import appContext from 'src/AppContext'
import SearchIcon from '@material-ui/icons/Search';
import { DataGrid } from '@material-ui/data-grid';
import Pager from 'src/utils/Pager'
import request from 'src/utils/request'
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {dateFormat } from 'src/utils/utils'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff'
  },
  toolbar: {
    marginBottom: 12
  }
}));

const InsureRank = () => {
  const classes = useStyles();
  const context = useContext(appContext)

  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(false)

  const [editOne, setEditOne] = useState()
  const [dialog, setDialog] = useState({
    open: false,
    title: ''
  })

  useEffect(() => {
    getIssues()
  }, [offset, keyword])

  const getIssues = () => {
    request({
      url: '/ajax/insurance/issues',
      method: 'get',
      data: {
        limit: 20,
        offset: offset,
        kw: keyword
      }
    }).then(res => {
        setRowCount(res.data.total)
        setRows(res.data.issues.map((item, index) => {
            item.aid = item.id
            item.id = index
            return item
        }))
        setLoading(false)
    })
  }

  const modify = (data, callback) => {
    request({
      url: `/ajax/insurance/issue/save`,
      method: 'post',
      data
    }).then(res => {
      callback(res)
    })
  }

  const updateState = (row, index) => {
    let newState = row.state === 1 ? 2 : 1
    request({
      url: `/ajax/insurance/issue/${row.aid}/state`,
      method: 'post',
      data: {
        state: newState
      }
    }).then(res => {
      let newRows = rows.concat()
      newRows[index].state = newState
      setRows(newRows)
      context.showToast('操作成功')
    })
  }

  const remove = (row, index) => {
    request({
      url: `/ajax/insurance/issue/${row.aid}/delete`,
      method: 'post',
    }).then(res => {
      let newRows = rows.concat()
      newRows.splice(index, 1)
      setRows(newRows)
      context.showToast('删除成功')
    })
  }

  const copy = (row) => {
    request({
      url: `/ajax/insurance/issue/${row.aid}/copy`,
      method: 'post',
    }).then(res => {
      context.showToast('复制成功')
      getIssues()
    })
  }

  const handleDialogClose = () => {
    setDialog({
      open: false,
      title: ''
    })
  }


  return <Page
    className={classes.root}
    title="保险榜单"
  >
    <Container maxWidth={false}>

        <Grid container className={classes.toolbar} justify="space-between">
          <Grid item xs={4} >
            <TextField
              label="搜索"
              fullWidth
              variant="outlined"
              color="primary"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              placeholder="输入关键字，按回车搜索"
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    setKeyword(e.target.value)
                }

                if (e.target.value === '') {
                    setKeyword('')
                }

                setOffset(0)
              }}
            ></TextField>
          </Grid>
          <Grid container item xs={4} justify="flex-end">
            <Grid item >
              <Button variant="contained" color="primary" onClick={() => {
                setDialog({
                  open: true,
                  title: ''
                })
              }}>
                新建
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <DataGrid components={{
            footer: Pager
          }}
          className={classes.table}
          autoHeight
          disableSelectionOnClick={true}
          rows={rows} 
          columns={[
            {
                field: 'aid',
                headerName: 'ID',
                width: 120
            },
            {
                field: 'title',
                headerName: '标题',
                width: 520,
                renderCell: (params) => (
                  <Grid container direction="row">
                    {editOne === params.row.aid ? 
                      <>
                        <Grid item direction="column">
                            <TextField
                              style={{marginTop: 6}}
                              variant="outlined"
                              defaultValue={params.row.title}
                              autoFocus
                              onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                  if (e.target.value) {
                                    let title = e.target.value
                                    modify({
                                      id: params.row.aid,
                                      title
                                    }, () => {
                                      let arr = rows.concat()
                                      arr[params.rowIndex].title = title
                                      setRows(arr)
                                      context.showToast('修改成功')
                                      setEditOne(null)
                                    })
                                  } else {
                                    context.showToast('标题不能为空')
                                  }
                                }
                              }}
                              />
                        </Grid>
                        <Grid item>
                          <Tooltip title="放弃修改">
                            <IconButton onClick={() => {
                              setEditOne(null)
                            }} aria-label="edit">
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </> : <>
                        <Grid item>{params.row.title}</Grid>
                        <Grid item>
                          <Tooltip title="修改标题">
                            <IconButton color="primary" onClick={() => {
                              setEditOne(params.row.aid)
                            }} aria-label="edit">
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </>}
                  </Grid>
                )
            },
            {
              field: 'utime',
              headerName: '创建时间',
              width: 220,
              valueGetter: (params) => {
                return dateFormat(new Date(params.value * 1000))
              }
            },
            {
                field: 'state',
                headerName: '状态',
                width: 120,
                align: 'center',
                sortable: false,
                valueGetter: (params) => {
                    switch(params.value) {
                    case 0: 
                        return '草稿'
                    case 1: 
                        return '线上'
                    case 2:
                        return '下线'
                    default: 
                        return ''
                    }
                }
            },
            {
                field: 'action',
                headerName: '操作',
                width: 420,
                sortable: false,
                renderCell: (params) => {
                    return <Grid container direction="row" spacing={1}>
                        {params.row.state !== 1 && <Grid item>
                            <Button size="small" color="primary" variant="contained" onClick={() => {
                                    updateState(params.row, params.rowIndex)
                                }}>上线</Button>
                        </Grid>}
                        {params.row.state === 1 && <Grid item>
                            <Button size="small" color="primary" variant="outlined" onClick={() => {
                                    updateState(params.row, params.rowIndex)
                                }}>下线</Button>
                        </Grid>}
                        <Grid item>
                            <Button href={`/app/insure/rank/edit/${params.row.aid}`} target="_blank"
                              size="small" color="primary" variant="contained">编辑榜单</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" color="primary" variant="contained" onClick={() => {
                               context.showConfirm({
                                  text: '确定要复制这个专题吗？',
                                  success: () => {
                                      copy(params.row)
                                      context.hideConfirm()
                                  }
                              })
                            }}>复制</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" color="primary" variant="outlined" onClick={() => {
                                context.showConfirm({
                                    text: '确定要删除吗？',
                                    success: () => {
                                        remove(params.row, params.rowIndex)
                                        context.hideConfirm()
                                    }
                                })
                            }}>删除</Button>
                        </Grid>
                        <Grid item>
                        <CopyToClipboard text={`pages/insurance/charts?id=${params.row.id}`}
                          onCopy={() => {
                              context.showToast('已复制到剪切板')
                          }}>
                          <Button size="small" color="default" variant="outlined">小程序链接</Button>
                        </CopyToClipboard>
                        </Grid>
                    </Grid>
                }
            }
          ]}
          pagination
          paginationMode="server"
          pageSize={20} 
          rowCount={rowCount}
          onPageChange={(params) => {
            setOffset((params.page - 1) * 20)
          }}
          loading={loading}
          ></DataGrid>
    </Container>
    <Dialog open={dialog.open} onClose={handleDialogClose}>
        <DialogTitle id="form-dialog-title">新建专题</DialogTitle>
        <DialogContent style={{width: 320, paddingBottom: 32}}>
          <TextField
            autoFocus
            margin="dense"
            label="标题"
            fullWidth
            defaultValue={dialog.title}
            onChange={e => {
              setDialog({
                open: true,
                title: e.target.value
              })
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="default" variant="text">
            取消
          </Button>
          <Button onClick={() => {
            modify({
              title: dialog.title
            }, () => {
              context.showToast('新建成功')
              handleDialogClose()
              getIssues()
            })
          }} color="primary" variant="contained">
            新建
          </Button>
        </DialogActions>
      </Dialog>
  </Page>
}

export default InsureRank