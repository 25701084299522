import React, { useEffect, useState,useContext } from 'react';
import {
    Grid,
    List,
    ListItem,
    ListItemText,
    TextField,
    InputLabel,
    Button,
    ListItemSecondaryAction,
    IconButton,
    ListItemIcon,
    Collapse
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ClearIcon from '@material-ui/icons/Clear'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import appContext from 'src/AppContext'
import request from 'src/utils/request'
import { Formik } from 'formik'
import * as Yup from 'yup'
import QuillEditor from 'src/utils/quillEditor'


const initialEntries = [
    {
        "parent_id": 0, 
        "title": "亮点",
        "icon": "highlights",
        "content": '[]'
    },
    {
        "parent_id": 0, 
        "title": "注意",
        "icon": "warning",
        "content": '[]'
    },
    {
        "parent_id": 0, 
        "title": "保障内容",
        "icon": "detail",
        "content": '[]',
        "is_group": 1
    }
]

const ProductDetail = ({
    id,
    data,
    ...rest
}) => {
  const context = useContext(appContext)
  const [entries, setEntries] = useState([])
  const [currentEntry, setCurrentEntry] = useState()
  const [loading, setLoading] = useState(true)
  const [newEntry, setNewEntry] = useState()

  useEffect(() => {
    getEntries()
  }, [])

  const getEntries = () => {
    request({
        url: `/ajax/insurance/product/${id}/entries`,
        method: 'get'
    }).then(res => {
        let arr = res.data.entries
        if (arr.length === 0) {
            createInitEntries()
        } else {
            getCurrentEntry(arr[0].id)
            setEntries(arr)
            setLoading(false)
        }
    })
  }

  const createInitEntries = () => {
      Promise.all(initialEntries.map((item, index) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
                modifyEntry(item, () => {
                    resolve()
                })
            }, index * 100)
          })
          
      })).then(values => {
        setTimeout(() => {
            getEntries()
        },100)
      })
  }

  const getCurrentEntry = (entry_id) => {
    request({
        url: `/ajax/insurance/entry/${entry_id}`,
        method: 'get'
    }).then(res => {
        let data = res.data.entry
        data.content = JSON.parse(data.content)
        setCurrentEntry(data)
    })
  }

  const modifyEntry = (data, callback) => {
        request({
            url: `/ajax/insurance/product/${id}/entry`,
            method: 'post',
            data
        }).then(res => {
            callback(res)
        })
  }

  const switchOrder = (parentIndex, index1, index2) => {
        let arr = entries.concat()
        let a = arr[parentIndex].entries[index1]
        let b = arr[parentIndex].entries[index2]
        request({
            url: `/ajax/insurance/product/${id}/entry/swap`,
            method: 'post',
            data: {
                id: `${a.id},${b.id}`
            }
        }).then(res => {
            let temp = a
            arr[parentIndex].entries[index1] = b
            arr[parentIndex].entries[index2] = temp
            setEntries(arr)
        })
  }

  const removeEntry = (entry, index, subIndex) => {
    context.showConfirm({
        text: '确定要删除吗？',
        success: () => {
            request({
                url: `/ajax/insurance/entry/${entry.id}/delete`,
                method: 'post'
            }).then(res => {
                context.hideConfirm()
                let arr = entries.concat()
                if (!subIndex) {
                    arr.splice(index, 1)
                } else {
                    arr[index].splice(subIndex, 1)
                }
                setEntries(arr)
                context.showToast('删除成功')
            })
        }
    })
  }


  return <Grid container direction="row" spacing={3} style={{minHeight: 500}}>
      {!loading && <>
        <Grid item container xs={3}>
            <List style={{width: '100%'}}>
                {entries.length > 0 && entries.map((en, index) => (
                    <>
                        <ListItem 
                            key={en.id}
                            button 
                            selected={currentEntry && currentEntry.id === en.id}
                            onClick={() => {
                                setCurrentEntry(undefined)
                                getCurrentEntry(en.id)
                            }}>
                            <ListItemIcon>
                            {en.is_group === 1 && <ExpandMore />} 
                            </ListItemIcon>
                            <ListItemText primary={en.title} />
                            <ListItemSecondaryAction>
                                {en.is_group === 1 && <IconButton color="primary" edge="end" size="small" onClick={() => {
                                    setNewEntry(en.id)
                                }}>
                                    <AddIcon />
                                </IconButton>}
                                <IconButton style={{marginLeft: 8}} edge="end" size="small" onClick={() => {
                                    removeEntry(en, index)
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        {en.is_group === 1 && <Collapse in>
                            <List style={{marginLeft: 24}}>
                                {en.entries.map((subEn, subIndex) => (
                                    <ListItem key={subEn.id}
                                        button 
                                        selected={currentEntry && currentEntry.id === subEn.id}
                                        onClick={() => {
                                            setCurrentEntry(undefined)
                                            getCurrentEntry(subEn.id)
                                        }}>
                                        <ListItemIcon></ListItemIcon>
                                        <ListItemText primary={subEn.title} />
                                        <ListItemSecondaryAction>
                                            <IconButton style={{marginLeft: 8}} color="primary" edge="end" size="small" 
                                                disabled={subIndex === 0}
                                                onClick={() => {
                                                    switchOrder(index, subIndex, subIndex - 1)
                                                }}>
                                                <ArrowUpwardIcon />
                                            </IconButton>
                                            <IconButton style={{marginLeft: 8}} color="primary" edge="end" size="small" 
                                                disabled={subIndex === en.entries.length - 1}
                                                onClick={() => {
                                                    switchOrder(index, subIndex, subIndex + 1)
                                                }}>
                                                <ArrowDownwardIcon />
                                            </IconButton>
                                            <IconButton style={{marginLeft: 8}} edge="end" size="small" onClick={() => {
                                                removeEntry(subEn, index, subIndex)
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                                {newEntry === en.id && <ListItem>
                                    <ListItemIcon></ListItemIcon>
                                    <TextField 
                                        variant="outlined" 
                                        size="small"
                                        placeholder="输入名称回车"
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                              if (e.target.value) {
                                                let data = {
                                                    parent_id: en.id, 
                                                    title: e.target.value, 
                                                    content: '[]',
                                                    icon: '', 
                                                    is_group: 0
                                                }

                                                modifyEntry(data, () => {
                                                    context.showToast('添加成功')
                                                    getEntries() 
                                                    setNewEntry(null)                                             
                                                })
                                              } else {

                                              }
                                            }
                                        }} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" size="small" onClick={() => {
                                            setNewEntry(null)
                                        }}>
                                            <ClearIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>}
                            </List>
                        </Collapse>}
                    </>
                ))}
            </List>
        </Grid>
        <Grid item xs={8}>
            {currentEntry && <Formik
                initialValues={currentEntry}
                validationSchema={Yup.object().shape({

                })}
                onSubmit={(values, { setSubmitting }) => {

                    let data = Object.assign({}, values)
                    data.content = JSON.stringify(data.content)
                    modifyEntry(data, () => {
                        context.showToast('保存成功')
                        setSubmitting(false)
                    })
                }}>
                {
                    ({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setFieldError,
                        touched,
                        values
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container direction="column" spacing={2}>
                                <Grid container item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="名称" 
                                        name="title"
                                        value={values.title}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(touched.title && errors.title)}
                                        helperText={touched.title && errors.title} />
                                </Grid>
                                <Grid container item xs={6} direction="column" spacing={1}>
                                    <Grid item>
                                        <InputLabel shrink>内容</InputLabel>
                                    </Grid>
                                    <Grid item>
                                        <QuillEditor 
                                            content={values.content}
                                            toolbar={values.parent_id === 0 ? ['bold'] : ['bold', 'table']}
                                            qiniuPrefix="insure/" 
                                            onChange={(content, html) => {
                                                setFieldValue('content', content.ops)
                                            }}></QuillEditor>
                                    </Grid>

                                </Grid>
                                <Grid item xs={2}>
                                    <Button color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained">保存</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }  
            </Formik>}
        </Grid>
      </>}
        
  </Grid>
}

export default ProductDetail