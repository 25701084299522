import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  makeStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Button,
  TextField,
  InputLabel,
  ButtonGroup,
  Switch
} from '@material-ui/core';
import Page from 'src/components/Page';
import appContext from 'src/AppContext'
import request from 'src/utils/request'
import { useParams } from "react-router-dom"
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import DeleteIcon from '@material-ui/icons/Delete'
import { Formik } from 'formik'
import * as Yup from 'yup'
import UploadImage from 'src/utils/uploadImage'
import RankProducts from 'src/views/insure/rank/products'



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  Container: {
    backgroundColor: theme.palette.background.default,
    padding: 20
  },
  pageTitle: {
    paddingBottom: 20
  },
}));

const InsureRankEdit = () => {
  const classes = useStyles()
  const context = useContext(appContext)

  let { id } = useParams()

  const [list, setList] = useState([])
  const [current, setCurrent] = useState()
  const [detail, setDetail] = useState()
  const [products, setProducts] = useState()
  const [tab, setTab] = useState('1')

  useEffect(() => {
    getList()
  }, [])

  useEffect(() => {
    if (current && current.id) {
      if (tab === '1') {
        getDetail()
      }

      if (tab === '2') {
        getProducts()
      }
      
    }
  }, [current, tab])

  const getList = () => {
    request({
        url: `/ajax/insurance/issue/${id}/list`,
        method: 'get'
    }).then(res => {
      setList(res.data.list)
      if (!current && res.data.list.length > 0) {
        setCurrent(res.data.list[0])
      }
    })
  }

  const switchOrder = (index1, index2) => {
    let arr = list.concat()
    let a = arr[index1]
    let b = arr[index2]
    request({
        url: `/ajax/insurance/issue/${id}/list/swap`,
        method: 'post',
        data: {
            id: `${a.id},${b.id}`
        }
    }).then(res => {
        let temp = a
        arr[index1] = b
        arr[index2] = temp
        setList(arr)
    })
  }

  const remove = (list_id, index) => {
    context.showConfirm({
      text: '确定要删除吗？',
      success: () => {
          request({
              url: `/ajax/insurance/list/${list_id}/delete`,
              method: 'post'
          }).then(res => {
              context.hideConfirm()
              let arr = list.concat()
              arr.splice(index, 1)
              setList(arr)
              context.showToast('删除成功')
          })
      }
    })
  }

  const modify = (data, callback) => {
    request({
        url: `/ajax/insurance/issue/${id}/list`,
        method: 'post',
        data
    }).then(res => {
        callback(res)
    })
  }

  const getDetail = (id) => {
    setDetail(undefined)
    request({
        url: `/ajax/insurance/list/${id || current.id}`,
        method: 'get'
    }).then(res => {
      if (res.data.list) {
        let detail = res.data.list
        let theme = detail.theme
        detail.bg_head = theme.bg_head ? theme.bg_head.replace('https://pic.lmtest.cn/','') : ''
        detail.bg_body = theme.bg_body ? theme.bg_body.replace('https://pic.lmtest.cn/','') : ''
        setDetail(detail)
      }
    })
  }

  const getProducts = (id) => {
    request({
      url: `/ajax/insurance/list/${id || current.id}/products`,
      method: 'get'
    }).then(res => {
      setProducts(res.data.products)
    })
  }

  const add = () => {
    setCurrent(null)
    setDetail(null)
    setTimeout(() => {
      setCurrent({
        name: '',
        title: ''
      })

      setDetail({
        name: '',
        title: '',
        bg_head: '',
        bg_body: ''
      })
    }, 100)
    
  }

  const updateState = (item, index) => {
    let newState = item.state === 1 ? 2 : 1
    request({
      url: `/ajax/insurance/list/${item.id}/state`,
      method: 'post',
      data: {
        state: newState
      }
    }).then(res => {
      let arr = list.concat()
      arr[index].state = newState
      setList(arr)
      context.showToast(`已将${item.name}设置为${newState === 1 ? `上线` : `下线`}`)
    })
  }

  return <Page
    className={classes.root}
    title="编辑专题榜单"
  >
    <Container maxWidth={false} className={classes.Container}>
      <h2 className={classes.pageTitle}>编辑专题榜单</h2>
      <hr/>
      <Grid container direction="row" style={{padding: 18}}>
        <Grid item>
        </Grid>
      </Grid>
      <Grid container direction="row" style={{minHeight: 500}} spacing={2}>
        <Grid item container xs={3} direction="column" spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={add}>新建榜单</Button>
          </Grid>
          <Grid item>
            <List>
              {list.map((item, index) => (
                <ListItem key={item.id} button selected={current && current.id === item.id}
                  onClick={() => {
                    setCurrent(null)
                    setTimeout(() => {
                      setCurrent(item)
                    }, 100)
                  }}>
                  <Switch checked={item.state === 1} onClick={e => {
                    e.stopPropagation()
                  }} onChange={e => {
                    updateState(item, index)
                  }}></Switch>
                  <ListItemText primary={item.name} />
                  <ListItemSecondaryAction>
                    <IconButton style={{marginLeft: 8}} color="primary" edge="end" size="small" 
                        disabled={index === 0}
                        onClick={() => {
                            switchOrder(index, index - 1)
                        }}>
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton style={{marginLeft: 8}} color="primary" edge="end" size="small" 
                        disabled={index === list.length - 1}
                        onClick={() => {
                            switchOrder(index, index + 1)
                        }}>
                        <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton style={{marginLeft: 8}} edge="end" size="small" onClick={() => {
                      remove(item.id, index)
                    }}>
                        <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        {current && <Grid item container xs={9} direction="column" spacing={2}>
          <Grid item>
            <ButtonGroup color="primary" >
              <Button disabled={!current} variant={tab === '1' ? 'contained' : 'outlined'} onClick={() => {setTab('1')}}>基础信息</Button>
              <Button disabled={!current || !current.id} variant={tab === '2' ? 'contained' : 'outlined'} onClick={() => {setTab('2')}}>产品列表</Button>
            </ButtonGroup>
          </Grid>
          {tab === '1' && current && detail && <Grid item>
              <Formik
                initialValues={detail}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required('请输入榜单名'),
                  title: Yup.string().required('请输入榜单标题')
                })}
                onSubmit={(values, { setSubmitting }) => {
                   let {name, title, sub_title, bg_body, bg_head } = values
                   let theme = JSON.stringify({
                    bg_body: bg_body ? `https://pic.lmtest.cn/${bg_body}` : '',
                    bg_head: bg_head ? `https://pic.lmtest.cn/${bg_head}` : ''
                   })

                   let data = {name, title, sub_title, theme}
                   if (values.id) {
                     data.id = values.id
                   }

                   modify(data, () => {
                      setSubmitting(false)
                      if (!data.id) {
                        context.showToast('新建成功')
                        window.location.reload()
                      } else {
                        context.showToast('保存成功')
                      }
                   })
                }}>
                  {
                    ({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      setFieldError,
                      touched,
                      values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container item direction="column" xs={4} spacing={2} >
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="榜单名"
                            required 
                            name="name"
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}/>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="榜单标题"
                            required 
                            name="title"
                            value={values.title}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}/>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="榜单副标题"
                            required 
                            name="sub_title"
                            value={values.sub_title}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.sub_title && errors.sub_title)}
                            helperText={touched.sub_title && errors.sub_title}/>
                        </Grid>
                        <Grid container item xs={12} direction="column">
                            <InputLabel shrink>背景头图</InputLabel>
                            <UploadImage 
                                width={185} 
                                height={100} 
                                prefix="insure/"
                                image={values.bg_head}
                                text="上传背景头图"
                                onChange={(val) => {
                                    setFieldValue('bg_head', val)
                                }}
                                error={Boolean(touched.bg_head && errors.bg_head)}></UploadImage>
                        </Grid>
                        <Grid container item xs={12} direction="column">
                            <InputLabel shrink>背景图</InputLabel>
                            <UploadImage 
                                width={185} 
                                height={100} 
                                prefix="insure/"
                                image={values.bg_body}
                                text="上传背景头图"
                                onChange={(val) => {
                                    setFieldValue('bg_body', val)
                                }}
                                error={Boolean(touched.bg_body && errors.bg_body)}></UploadImage>
                        </Grid>
                        <Grid item xs={4}>
                            <Button color="primary"
                                disabled={isSubmitting}
                                size="large"
                                type="submit"
                                variant="contained">{values.id ? '保存': '新建'}</Button>
                        </Grid>
                      </Grid>
                    </form>
                  )
                  }
                </Formik>
          </Grid>}
          {tab === '2' && current && products && <Grid item>
            <RankProducts products={products} id={current.id} onChange={(newList) => {
              setProducts(newList)
            }} />
            </Grid>}
        </Grid>}
      </Grid>
    </Container>
  </Page>
}

export default InsureRankEdit