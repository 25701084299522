import React, {useEffect, useState, useContext} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    makeStyles,
    TextField,
    Grid,
    Button,
    InputLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormHelperText,
  } from '@material-ui/core';
  import Page from 'src/components/Page';
  import { useParams, useNavigate } from "react-router-dom";
  import UploadImage from 'src/utils/uploadImage'
  import request from 'src/utils/request'
  import appContext from 'src/AppContext'
  import QuillEditor from 'src/utils/quillEditor'

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      padding: theme.spacing(3)
    },
    Container: {
      backgroundColor: theme.palette.background.default,
      padding: 20
    },
    pageTitle: {
      paddingBottom: 20
    },
    form: {
      paddingTop: 20
    }
  }));

  const formatDate = (d) => {
    let month = d.getMonth() + 1
    let date = d.getDate()
    month = month > 9 ? month : `0${month}`
    date = date > 9 ? date : `0${date}`
  
    return `${d.getFullYear()}-${month}-${date}`
  }

  const BokeEdit = () => {
    const classes = useStyles();
    const context = useContext(appContext)
    const navigate = useNavigate();

    let { id } = useParams();
    let pageTitle = id !== undefined ? '编辑播客' : '添加播客'
    const [data, setData] = useState({
        "id": id || 0,
        "title": "",
        "sub_title": "",
        "author": "",
        "img": "", //封图，七牛文件key
        "media_url": "", 
        "cate_id": "", 
        "cate_name": "", 
        "pub_date": "", 
        "pub_date_format": formatDate(new Date()),
        "desc": "", 
        "duration": "",
        "state": 0,
      })
    const [loading, setLoading] = useState(true)
    const [tags, setTags] = useState([])
    const [mediaUrl, setMediaUrl] = useState()

    useEffect(() => {
        request({
            url: '/ajax/boke/catelist',
            method: 'get'
        }).then(res => {
            if (res.data) {
                setTags(res.data)
            }
        })
        
    }, [])

    const getPubTimeFormat = (data) => {
      return data.pub_date ? formatDate(new Date(data.pub_date*1000)) : ''
    }


    useEffect(() => {
        if (id) {
          request({
            url: '/ajax/boke/info',
            method: 'get',
            data: {
              id
            }
          }).then(res => {
            res.data.pub_date_format = getPubTimeFormat(res.data)
            setData(res.data)
            setMediaUrl(res.data.media_url)
            setLoading(false)
          })
        } else {
          setLoading(false)
        }
        
      }, [id])

    return <Page className={classes.root} title={pageTitle} >
        <Container className={classes.Container} maxWidth={false}>
          <h2 className={classes.pageTitle}>{pageTitle}</h2>
          <hr/>

          {!loading && data && <Formik
            initialValues={data}
            validationSchema={Yup.object().shape({
              title: Yup.string().required('请输入标题'),
              sub_title: Yup.string().required('请输入副标题'),
              author: Yup.string().required('请填写作者'),
              // img: Yup.string().required('请上传封图'),
              // media_url: Yup.string().required('请上传音频'),
              cate_id: Yup.string().required('请选择分类'),
              duration: Yup.string().matches(/\d+\:\d+(\:\d+)?/, '请填写正确格式，例: 1:05:00').required('请填写音频时长'),
              desc: Yup.string().required('请填写描述')
            })}
            onSubmit={(values, { setSubmitting }) => {

              if (!values.img) {
                context.showToast('请上传封图')
                return
              }

              if (!mediaUrl) {
                context.showToast('请上传音频')
                return
              } else {
                values.media_url = mediaUrl
              }

              if (values.pub_date_format) {
                values.pub_date = Math.floor(new Date(values.pub_date_format).getTime()/1000)
              }

              if (values.cate_id) {
                let obj = tags.find(item => {
                  return item.id === values.cate_id
                })
                values.cate_name = obj.cate_name
              }

              request({
                url: '/ajax/boke/save',
                data: values
              }).then(res => {
                setSubmitting(false)
                context.showToast('保存成功')
                setTimeout(() => {
                  if (!id) {
                    navigate('/app/boke', { replace: true });
                  }
                }, 500)
              }, err => {
                setSubmitting(false)
                context.showToast(err.message)
              })
            }}>
            {
              ({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={2}>
                    <Grid container item xs={8}>
                      <TextField
                         fullWidth
                         label="标题" 
                         name="title"
                         value={values.title}
                         onBlur={handleBlur}
                         onChange={handleChange}
                         error={Boolean(touched.title && errors.title)}
                         helperText={touched.title && errors.title}/>
                    </Grid>
                    <Grid container item xs={8}>
                      <TextField
                         fullWidth
                         label="副标题" 
                         name="sub_title"
                         value={values.sub_title}
                         onBlur={handleBlur}
                         onChange={handleChange}
                         error={Boolean(touched.sub_title && errors.sub_title)}
                         helperText={touched.sub_title && errors.sub_title}/>
                    </Grid>
                    <Grid container item xs={8}>
                      <TextField
                         fullWidth
                         label="作者" 
                         name="author"
                         value={values.author}
                         onBlur={handleBlur}
                         onChange={handleChange}
                         error={Boolean(touched.author && errors.author)}
                         helperText={touched.author && errors.author}/>
                    </Grid>
                    <Grid container item xs={8} direction="column">
                      <InputLabel shrink>封图</InputLabel>
                      <UploadImage 
                        width={100} 
                        height={100} 
                        prefix="boke/pic/"
                        image={values.img}
                        onChange={(val) => {
                          values.img = val
                          if (val) {
                            delete errors.img
                          }
                        }}
                        error={Boolean(touched.img && errors.img)}
                        ></UploadImage>
                    </Grid>
                    <Grid container item xs={8} direction="column">
                      <InputLabel shrink>音频文件</InputLabel>
                      <UploadImage 
                        width={100} 
                        height={100} 
                        prefix="boke/b/"
                        text="上传音频"
                        type="audio"
                        image={values.media_url}
                        onChange={(val) => {
                          setMediaUrl(val)
                          // console.log(val)
                          // values.media_url = val
                          // console.log(values.media_url)
                          // if (val) {
                          //   delete errors.media_url
                          // }
                        }}
                        error={Boolean(touched.media_url && errors.media_url)}
                        ></UploadImage>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField 
                        fullWidth
                        label="音频长度" 
                        name="duration"
                        placeholder="hh:mm:ss"
                        value={values.duration}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.duration && errors.duration)}
                        helperText={touched.duration && errors.duration}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <InputLabel shrink>选择分类</InputLabel>
                      <RadioGroup 
                        name="cate_id" 
                        row 
                        defaultValue={Object.assign({}, values).cate_id}
                        onChange={handleChange}
                        >
                        {tags.map(item => {
                          return <FormControlLabel value={item.id} control={<Radio />} label={item.cate_name} />
                        })}
                      </RadioGroup>
                      {touched.cate_id && errors.cate_id && <FormHelperText error>{errors.cate_id}</FormHelperText>}
                    </Grid>
                    <Grid container item xs={2} direction="column">
                      <InputLabel shrink>发布时间</InputLabel>
                      <TextField
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="pub_date_format"
                        value={values.pub_date_format}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      {/* <TextField 
                        disabled={data.allow_edit === 0}
                        fullWidth
                        label="描述" 
                        name="desc"
                        multiline
                        rows={5}
                        value={values.desc}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.desc && errors.desc)}
                        helperText={touched.desc && errors.desc}
                      /> */}
                      <Grid item container direction="column" spacing={1}>
                        <Grid item>
                          <InputLabel shrink>描述</InputLabel>
                          </Grid>
                        <Grid item>
                          <QuillEditor 
                            html={values.desc}
                            qiniuPrefix="boke/desc/" 
                            onChange={(content, html) => {
                              values.desc = html
                              if (values.desc) {
                                delete errors.desc
                              }
                            }}></QuillEditor>
                            {touched.desc && errors.desc && <FormHelperText error>{errors.desc}</FormHelperText>}
                        </Grid>
                        
                      </Grid>
                    </Grid>
                    
                    <Grid item xs={2}>
                      <Button color="primary"
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained">保存</Button>
                    </Grid>
                  </Grid>

                </form>
              )
            }
              
              
          </Formik>}
        </Container>
    </Page>
  }

  export default BokeEdit