import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Typography,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Link,
  FormControl,
  Select,
  Checkbox,
  Switch
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Page from 'src/components/Page';
import request from 'src/utils/request'
import appContext from 'src/AppContext'
import SearchIcon from '@material-ui/icons/Search';
import Pager from 'src/utils/Pager'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  toolbar: {
    marginBottom: 12
  },
  table: {
    backgroundColor: '#ffffff'
  },
  Typography: {
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
  numberTextField: {
    width: 70,
    margin: '0 auto'
  },
  dialog: {
    width: 400
  },
  cellcate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  select: {
    width: 84,
    '&::before' : {
      display: 'none'
    }
  },
  checkbox: {
    padding: 2
  }
}));

const Articles = () => {
  const classes = useStyles();
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(false)
  const [tagLib, setTagLib] = useState({})
  const [dialog, setDialog] = useState({
    open: false
  })
  const [keyword, setKeyword] = useState('')
  const [selected, setSelected] = useState({
    tag_id: '',
    is_digest: false,
    state: ''
  })
  const [sortModel, setSortModel] = useState([])
  const [reload, setReload] = useState(false)

  const context = useContext(appContext)

  const goReload = () => {
    setReload(false)
    setTimeout(() => {
      setReload(true)
    })
  }

  useEffect(() => {
    setLoading(true)
    let sm = sortModel[0]
    request({
      url: '/ajax/post/list',
      method: 'get',
      data: {
        limit: 20,
        offset: offset,
        kw: keyword,
        tag_id: selected.tag_id,
        is_digest: selected.is_digest ? 1 : 0,
        sort_pub_time: sm ? (sm.sort || '') : '',
        state: selected.state
      }
    }).then(res => {
      if (offset === 0) {
        goReload()
      }
      setRows(res.data.list.map((item, index) => {
        item.aid = item.id
        item.id = index
        return item
      }))
      setRowCount(res.data.total)
      setLoading(false)
    })
  }, [offset, keyword, selected, sortModel])

  useEffect(() => {
    setLoading(true)
    request({
      url: '/ajax/tag/list',
      method: 'get'
    }).then(res => {
      if (res.data) {
        let obj = {}
        res.data.list.forEach(item => {
          obj[item.id.toString()] = item.name
        })
        setTagLib(obj)
      }
      setLoading(false)
    })
  }, [])

  const updateState = (row, index) => {
    let newState = row.state === 1 ? 0 : 1
    request({
      url: '/ajax/post/set_state',
      method: 'post',
      data: {
        id: row.aid,
        state: newState
      }
    }).then(res => {
      let newRows = rows.concat()
      newRows[index].state = newState
      setRows(newRows)
      context.showToast('操作成功')
    })
  }

  

  const remove = () => {
    let row = dialog.row
    let index = dialog.index
    request({
      url: '/ajax/post/del',
      method: 'post',
      data: {
        id: row.aid
      }
    }).then(res => {
      let newRows = rows.concat()
      newRows.splice(index, 1)
      setRows(newRows)
      closeDialog()
      context.showToast('删除成功')
    })
  }

  const closeDialog = () => {
    setDialog({
      open: false
    })
  }

  const updateSort = (row, value, index) => {
    request({
      url: '/ajax/post/sort',
      method: 'post',
      data: {
        id: row.aid,
        sort: value
      }
    }).then(res => {
      let newRows = rows.concat()
      newRows[index].sort = value
      setRows(newRows)
    })
  }

  const updateDigest = (row, value, index) => {
    request({
      url: '/ajax/post/set_digest',
      method: 'post',
      data: {
        id: row.aid,
        digest: value
      }
    }).then(res => {
      let newRows = rows.concat()
      newRows[index].digest = value
      setRows(newRows)
    })
  }

  return (
    <Page
      className={classes.root}
      title="文章列表"
    >
      <Container maxWidth={false}>
        <Grid container className={classes.toolbar} justify="space-between">
          <Grid item xs={4} >
            <TextField
              label="搜索"
              fullWidth
              variant="outlined"
              color="primary"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              placeholder="输入关键字，按回车搜索"
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setKeyword(e.target.value)
                  setOffset(0)
                }

                if (e.target.value === '') {
                  setKeyword('')
                  setOffset(0)
                }
                
              }}
            ></TextField>
          </Grid>
          <Grid container item xs={4} justify="flex-end">
            <Grid item >
              <Button href="/app/articles/add" target="_blank"
                variant="contained"
                color="primary">
                添加文章
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {reload && <DataGrid 
          components={{
            footer: Pager
          }}
          className={classes.table}
          autoHeight
          disableSelectionOnClick={true}
          rows={rows} 
          sortingMode='server'
          sortingOrder={['desc', 'asc', null]} 
          sortModel={sortModel}
          onSortModelChange={(params) => {
            setSortModel(params.sortModel)
          }}
          columns={[
            {
              field: 'aid',
              headerName: 'ID',
              width: 80,
              sortable: false
            },
            {
              field: 'title',
              headerName: '文章标题',
              width: 400,
              sortable: false,
              renderCell: (params) => {
                return <Link href={params.row.url} target="_blank">{params.row.title}</Link>
              }
            },
            {
              field: 'cate',
              headerName: '文章类别',
              width: 140,
              className: classes.cellcate,
              sortable: false,
              valueGetter: (params) => {
                return params.row.tag_ids ? params.row.tag_ids.split(',').map(item => {
                  return tagLib[item]
                }).join(',') : ''
              },
              renderHeader: () => {
                return <FormControl>
                <Select
                  className={classes.select}
                  native
                  color="primary"
                  variant="standard"
                  value={selected.tag_id}
                  onChange={(e) => {
                    setSelected({
                      ...selected,
                      tag_id: e.target.value,
                    })
                    setOffset(0)
                  }}
                  inputProps={{
                    style: {
                      height: 40,
                      fontSize: '0.875em',
                      fontWeight: 500
                    }
                  }}
                >
                  <option value="">全部类别</option>
                  {Object.keys(tagLib).map(key => {
                    return <option value={key}>{tagLib[key]}</option>
                  })}
                </Select>
              </FormControl>
              }
            },
            {
              field: 'digest',
              headerName: '精选',
              width: 120,
              align: 'center',
              sortable: false,
              renderCell: (params) => {
                return <Switch checked={params.value===1} onChange={(e) => {
                  let checked = e.target.checked
                  updateDigest(params.row, checked? 1 : 0, params.rowIndex)
                }}></Switch>
              },
              renderHeader: () => {
                return <div>
                  <Checkbox 
                    className={classes.checkbox} 
                    color="primary"
                    checked={selected.is_digest}
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        is_digest: e.target.checked,
                      })
                      setOffset(0)
                    }}
                    ></Checkbox>
                  <label style={{fontWeight: 500}}>只看精选</label>
                </div>
              }
            },
            {
              field: 'time',
              headerName: '发布时间',
              width: 120,
              type: 'date',
              valueGetter: (params) => {
                return params.row.pub_time ? new Date(params.row.pub_time * 1000) : '-'
              }
            },
            {
              field: 'sort',
              headerName: '排序',
              width: 120,
              sortable: false,
              renderCell: (params) => {
                return  <>
                {!loading && <TextField
                  className={classes.numberTextField}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 0,
                    max: 999
                  }}
                  variant="outlined"
                  defaultValue={params.row.sort}
                  onKeyUp={(e) => {
                    if (parseInt(e.target.value) < 0) {
                      e.target.value = 0
                    }

                    if (parseInt(e.target.value) > 999) {
                      e.target.value = 999
                    }
                  }}
                  onChange={(e) => {
                    let sort = parseInt(e.target.value)
                    if (sort >= 0 && sort <= 999) {
                      updateSort(params.row, e.target.value, params.rowIndex)
                    }
                  }}
                />}
                </>
              }
            },
            {
              field: 'state',
              headerName: '状态',
              width: 120,
              align: 'center',
              sortable: false,
              valueGetter: (params) => {
                switch(params.value) {
                  case 0: 
                    return '草稿'
                  case 1: 
                    return '线上'
                  default: 
                    return ''
                }
              },
              renderHeader: () => {
                return <FormControl>
                <Select
                  className={classes.select}
                  native
                  color="primary"
                  variant="standard"
                  value={selected.state}
                  onChange={(e) => {
                    setSelected({
                      ...selected,
                      state: e.target.value,
                    })
                    setOffset(0)
                  }}
                  inputProps={{
                    style: {
                      height: 40,
                      fontSize: '0.875em',
                      fontWeight: 500
                    }
                  }}
                >
                  <option value="">全部状态</option>
                  <option value={0}>草稿</option>
                  <option value={1}>线上</option>
                </Select>
              </FormControl>
              }
            },
            {
              field: 'actions',
              headerName: '操作',
              width: 270,
              sortable: false,
              renderCell: (params) => {
                return <Typography className={classes.Typography}>
                {params.row.state === 0 && 
                  <Button 
                    size="small" 
                    color="primary" 
                    variant="contained"
                    onClick={() => {
                      updateState(params.row, params.rowIndex)
                    }}
                  >
                  上线
                </Button>}
                {params.row.state === 1 && 
                  <Button 
                    size="small" 
                    color="primary" 
                    variant="outlined"
                    onClick={() => {
                      updateState(params.row, params.rowIndex)
                    }}
                    >
                  下线
                </Button>}
                <Button href={`/app/articles/edit/${params.row.aid}`} target="_blank" color="primary" variant="contained" size="small">
                  编辑
                </Button>
                <Button 
                    size="small" 
                    color="primary" 
                    variant="outlined"
                    onClick={() => {
                      setDialog({
                        open: true,
                        row: params.row,
                        index: params.rowIndex
                      })
                    }}
                    >
                  删除
                </Button>
                {/* <Button href={params.row.url} target="_blank" size="small" variant="outlined">
                  查看文章
                </Button> */}
              </Typography>
              }
            }
          ]} 
          pagination
          paginationMode="server"
          pageSize={20} 
          rowCount={rowCount}
          onPageChange={(params) => {
            setOffset((params.page - 1) * 20)
          }}
          loading={loading} />}
      </Container>
      <Dialog
        open={dialog.open}
        onClose={closeDialog}
      >
        <DialogTitle>提示</DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            确定要删除吗？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="default">
            否
          </Button>
          <Button onClick={() => {
            remove()
          }} color="primary" autoFocus variant="contained">
            是
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default Articles;
