import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
    ListItemText,
    Button,
    Divider,
    TextField,
    Tooltip,
    IconButton,
    ListItemSecondaryAction,
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import appContext from 'src/AppContext'
import request from 'src/utils/request'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'



const RankProducts = ({
    id,
    products,
    onChange
}) => {

    const context = useContext(appContext)

    const limit = 20

    const [list, setList] = useState([])
    const [restList, setRestList] = useState([])
    const [keyword, setKeyword] = useState('')

    const [leftChecked, setLeftChecked] = useState([])
    const [rightChecked, setRightChecked] = useState([])
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)

    useEffect(() => {
        request({
        url: '/ajax/insurance/products',
        method: 'get',
        data: {
            limit,
            offset: (page - 1) * limit,
            kw: keyword
        }
        }).then(res => {
            let total = res.data.total
            setList(res.data.products)
            filterList(res.data.products)
            setPageCount(Math.ceil(total/limit))
        })
    }, [page, keyword])

    useEffect(() => {
        filterList()
    }, [list, products])


    const filterList = (olist) => {
        let copy = olist || list.concat()
        if (products && products.length > 0) {
            copy = copy.filter(item => {
                return Boolean(!products.find(pitem => pitem.id === item.id))
            })
        } 
        setRestList(copy)

    }

    const handleLeft = (e, item) => {
        let copy = [] 
        if (item) {
            copy = leftChecked.concat()
            if (e.target.checked) {
                copy.push(item.id)
            } else {
                let index = copy.findIndex(citem => citem.id === item.id)
                copy.splice(index, 1)
            }
        } else {
            if (e.target.checked) {
                copy = restList.map(item => item.id)
            }
        }

        setLeftChecked(copy)
        
    }

    const handleRight = (e, item) => {
        let copy = []
        if (item) {
            copy = rightChecked.concat()
            if (e.target.checked) {
                copy.push(item.id)
            } else {
                let index = copy.findIndex(citem => citem.id === item.id)
                copy.splice(index, 1)
            }
        } else {
            if (e.target.checked) {
                copy = products.map(item => item.id)
            }
        }
        
        setRightChecked(copy)
    }

    const moveToRight = () => {
        if (leftChecked.length > 0) {
            let newProductList = products.concat(restList.filter(item => leftChecked.indexOf(item.id) !== -1))
            onChange(newProductList)
        }
    }

    const moveToLeft = () => {
        if (rightChecked.length > 0) {
            let newProductList = products.filter(item => rightChecked.indexOf(item.id) === -1)
            onChange(newProductList)
        }
    }

    const switchOrder = (index1, index2) => {
        let arr = products.concat()
        let temp = arr[index1]
        arr[index1] = arr[index2]
        arr[index2] = temp
        onChange(arr)
        
      }
    

    const CustomList = ({items, checkedList, sortable, onCheckedChange, onOrderChange}) => (
        <Paper style={{height: 480}}>
            <List dense component="div" role="list">
                <ListItem button>
                    <ListItemIcon>
                        <Checkbox 
                         checked={checkedList.length === items.length}
                         onChange={(e) => {
                            onCheckedChange(e)
                        }} />
                    </ListItemIcon>
                    <ListItemText primary="全选" 
                        secondary={`${checkedList.length}/${items.length}`}></ListItemText>
                </ListItem>
                <Divider />
                {items.map((item, index) => (
                    <ListItem button key={item.id} role="listitem">
                        <ListItemIcon>
                            <Checkbox 
                                checked={checkedList.indexOf(item.id) !== -1}
                                onChange={(e) => {
                                    onCheckedChange(e, item)
                                }} />
                        </ListItemIcon>
                        <ListItemText id={item.id} primary={item.title} secondary={item.company_name} />
                        {sortable && <ListItemSecondaryAction>
                            <IconButton style={{marginLeft: 8}} color="primary" edge="end" size="small" 
                                disabled={index === 0}
                                onClick={() => {
                                    onOrderChange(index, index - 1)
                                }}>
                                <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton style={{marginLeft: 8}} color="primary" edge="end" size="small" 
                                disabled={index === items.length - 1}
                                onClick={() => {
                                    onOrderChange(index, index + 1)
                                }}>
                                <ArrowDownwardIcon />
                            </IconButton>
                        </ListItemSecondaryAction>}
                    </ListItem>
                ))}
            </List>
        </Paper>
    )

    const setPageByInput = (e) => {
        let start = 1
        let end = pageCount
        if (e.key === 'Enter') {
            let value = e.target.value
            if (parseInt(value) !== NaN && parseInt(value) >= start && parseInt(value) <= end) {
                setPage(parseInt(value))
            } else {
                context.showToast(`请输入${start} - ${end}数字`)
            }
        }
    }

    const save = () => {
        let data = {}
        products.forEach((p, i) => {
            data[`product_id[${i}]`] = p.id
        })
        request({
            url: `/ajax/insurance/list/${id}/products`,
            method: 'post',
            data: data
        }).then(res => {
            context.showToast('保存成功')
        })
    }


    return <Grid container direction="column" spacing={3}>
        <Grid container item direction="row" spacing={2}>
            <Grid item xs={5} container justify="space-between" direction="row">
                <Grid item xs={7}>
                    <TextField fullWidth variant="outlined" size="small"
                        placeholder="输入关键字，按回车搜索" 
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                setKeyword(e.target.value)
                            }
            
                            if (e.target.value === '') {
                                setKeyword('')
                            }
            
                            setPage(1)
                            }
                        }></TextField>
                </Grid>
                <Grid item xs={4} container direction="row" alignItems="center">
                    <Grid item>
                        <Tooltip title={page === 1 ? `已经是第一页了`:`上一页`}>
                            <IconButton size="small" onClick={() => {
                                if (page !== 1) {
                                    setPage(page - 1)
                                }
                            }} aria-label="before">
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <TextField style={{width: 56}} variant="outlined" size="small"
                            defaultValue={page}
                            onKeyUp={setPageByInput} />
                    </Grid>
                    <Grid item>
                        <Tooltip title={page === pageCount ? `已经是最后一页了` : `下一页`}>
                            <IconButton size="small" onClick={() => {
                                if (page !== pageCount) {
                                    setPage(page + 1)
                                }
                            }} aria-label="next">
                                <NavigateNextIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5} container justify="space-between">
                <Grid item>已选产品</Grid>
            </Grid>
        </Grid>
        <Grid container item direction="row" spacing={1}>
            <Grid item xs={5}>
                <CustomList items={restList} checkedList={leftChecked} onCheckedChange={handleLeft}></CustomList>    
            </Grid>
            <Grid item container direction="column" xs={2} alignItems="center" spacing={2}>
                <Grid item>
                    <Button variant="contained" color="primary"
                        disabled={leftChecked.length === 0} onClick={moveToRight}>{`>`}</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" 
                        disabled={rightChecked.length === 0} onClick={moveToLeft}>{`<`}</Button>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <CustomList items={products} 
                    checkedList={rightChecked} 
                    sortable={true} 
                    onCheckedChange={handleRight}
                    onOrderChange={switchOrder}></CustomList>    
                <Grid item container justify="flex-end">
                    <Button variant="contained" color="primary" style={{marginTop: 24}} onClick={save}>保存</Button>
                </Grid>
            </Grid>
        </Grid>
        

    </Grid>
    
    
}

export default RankProducts