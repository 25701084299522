import React, { useState, useEffect } from 'react';
import {
  Container,
  makeStyles,
  Tabs,
  Tab
} from '@material-ui/core';
import Page from 'src/components/Page';
import appContext from 'src/AppContext'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff'
  },
  toolbar: {
    marginBottom: 12
  },
  select: {
    width: 100,
    '&::before' : {
      display: 'none'
    }
  },
  dialog: {
    width: 400
  },
}));

const Example = () => {
  const classes = useStyles()
  const [tab, setTab] = useState('tab1')

  useEffect(() => {
    if (window.location.hash) {
      setTab(window.location.hash.replace('#', ''))
    } else {
      setTab('tab1')
    }
  }, [window.location.hash])

  return <Page
    className={classes.root}
    title="我是一个示例页面"
  >
    <Container maxWidth={false}>
        <Tabs 
            className={classes.tabs}
            value={tab} 
            onChange={(e, newValue) => {
                setTab(newValue)
                window.location.hash = newValue
            }}
            variant="fullWidth" 
            indicatorColor="primary">

            <Tab label="tab1" value={'tab1'} />
            <Tab label="tab2" value={'tab2'} />
        </Tabs>

        {tab === 'tab1' && <>我是tab1</>}
        {tab === 'tab2' && <>我是tab2</>}

    </Container>
  </Page>
}

export default Example