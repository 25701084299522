import React, {useEffect, useState, useContext} from 'react';
import {
    makeStyles,
    Container,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    Paper,
    Tooltip,
    Button,
  } from '@material-ui/core'
import { useParams } from "react-router-dom"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Rank from 'src/views/articles/rank'
import request from 'src/utils/request'
import UploadImage from 'src/utils/uploadImage'
import appContext from 'src/AppContext'
import QuillEditor, { getHTMLFromQuill } from 'src/utils/quillEditor'


  const useStyles = makeStyles((theme) => ({
    AccordionSummary: {
        width:  `100%`,
        display: "flex",
        justifyContent: "space-between"
    },
    heading: {
        fontSize: `0.875rem`,
        fontWeight: 600
      },
    secondaryHeading: {
        fontSize: `0.875rem`,
        color: theme.palette.text.secondary,
    },
    Paper: {
        width: '90%',
        height: 400,
        overflowY: 'auto',
        marginLeft: '5%'
    },
    AccordionDetails: {
        overflowX: 'scroll'
    }

  }));

  const args = [
      {
        key: 'rank_banner',
        name: '评测排名Banner',
        height: 76
      }, {
        key: 'rank_data',
        name: '评测排名',
        height: 240
      }, {
        key: 'content_banner',
        name: '评测冠军Banner',
        height: 76
      }, {
        key: 'content',
        name: '评测冠军',
        height: 240
      }
  ]

const Overview = () => {
    const classes = useStyles()
    const context = useContext(appContext)

    let { id } = useParams()

    const [expanded, setExpanded] = useState(false)
    const [originData, setOriginData] = useState({})
    const [html, setHtml] = useState()
    const [loaded, setLoaded] = useState(false)
    const [content, setContent] = useState()

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    useEffect(() => {
        request({
            url: `/ajax/post_eval/info?id=${id}`,
            method: 'get'
        }).then(res => {
            if (res.data) {
                setOriginData(res.data)
                if (res.data.content) {
                    setContent(res.data.content)
                    setHtml(getHTMLFromQuill({ops: res.data.content}))
                }
            }
            setLoaded(true)
        })
    }, [])

    const updateData = (key, value) => {
        let copy = Object.assign({}, originData)
        copy[key] = value

        let {rank_data, rank_banner, content, content_banner} = copy
        request({
            url: `/ajax/post_eval/save`,
            method: 'post',
            data: {
                id, 
                rank_data: JSON.stringify(rank_data) , 
                rank_banner: rank_banner || '', 
                content: JSON.stringify(content), 
                content_banner: content_banner || ''
            }
        }).then(res => {
            if (res.data) {
                setOriginData(copy)
                context.showToast('保存成功')
            }
        })

    }

    return <Container className={classes.Container}>
        <style>
            {`  
                .area:hover {
                    background-color: rgba(0, 0, 0, 0.15) !important;
                }

                 #richText {
                     padding: 12px 0;
                 }
                #richText p{
                    padding: 0 15px;
                    font-size: 14px;
                }

                #richText img {
                    width: 100%;
                }
            `}
        </style>
        {loaded && <Grid container direction="row" justify="space-between">
            <Grid item xs={8}>
                {args.map((item, index) => {
                    return  <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
                        <div className={classes.AccordionSummary}>
                            <Typography className={classes.heading}>{item.name}</Typography>
                            {expanded !== `panel${index}` && <Typography className={classes.secondaryHeading}>
                                {!originData[item.key] && '目前为空，点击填写'}
                                {originData[item.key] && '点击修改'}
                            </Typography>}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails style={{overflowX: 'auto', display: 'block'}}>
                        {(item.key === 'rank_banner' || item.key === 'content_banner') && <UploadImage 
                            width={375} 
                            height={76} 
                            text={`上传${item.name}`} 
                            prefix="banner/"
                            image={originData[item.key]}
                            onChange={(val) => {
                                updateData(item.key, val)
                            }}
                            error={false}
                        />}
                        {item.key === 'rank_data' && <Rank originData={originData} onSave={(data) => {
                            updateData(item.key, data)
                        }} />}
                        {/* {item.key === 'content' && <Brief originData={originData} onSave={(data) => {
                            updateData(item.key, data)
                        }}/>} */}
                        {item.key === 'content' && <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Button variant="contained" color="primary"
                                    onClick={() => {
                                        updateData(item.key, content)
                                    }}>保存</Button>
                            </Grid>
                            <Grid item>
                                <QuillEditor 
                                    content={originData.content}
                                    qiniuPrefix="brief/"
                                    onChange={(content, html) => {
                                        console.log(11)
                                        console.log(content)
                                        setContent(content.ops)
                                        setHtml(html)
                                    }}></QuillEditor>
                            </Grid>
                            
                            </Grid>}
                    </AccordionDetails>
                </Accordion>
                })}
            </Grid>
            <Grid item xs={4}>
                <Paper className={classes.Paper}>
                    <Grid container direction="column" style={{
                        display: 'block'
                    }} >
                        {args.map((item, index) => {
                            return <Tooltip title={`点击修改`} placement="right">
                                <Grid class="area" item 
                                onClick={() => {
                                    setExpanded(`panel${index}`)
                                }}
                                style={{minHeight: item.height, backgroundColor: `rgba(0, 0, 0, ${((index + 1)%2)*0.05})`}}>
                                {(item.key === 'rank_banner' || item.key === 'content_banner') && 
                                    <>
                                    {originData[item.key] && <img src={`//pic.lmtest.cn/${originData[item.key]}?imageView2/1/w/375/h/${item.height}`} />}
                                    {!originData[item.key] && <>
                                        {item.key === 'rank_banner' && <img src={`//pic.lmtest.cn/default/banner/eval1.jpg?imageView2/1/w/375/h/${item.height}`} />}
                                    </>}
                                    </>}
                                {item.key === 'rank_data' && <>
                                    {originData[item.key] && originData[item.key].length > 0 && <div style={{
                                        width: '100%',
                                        overflow: 'hidden',
                                        paddingBottom: 24
                                    }}>
                                        <div style={{
                                            marginLeft: 15,
                                            marginTop: 0
                                        }}>
                                            <div style={{
                                                paddingTop: 18,
                                                whiteSpace: 'nowrap',
                                                wordBreak: 'break-all',
                                                textOverflow: 'ellipsis',
                                            }}>
                                                {originData[item.key][0].slice(3).map((cate, cindex) => {
                                                    return <span style={{
                                                        display: 'inline-block',
                                                        padding: `4px 8px`,
                                                        fontSize: 14,
                                                        color: '#636363',
                                                        fontWeight: cindex === 0 ? 'bold': 'normal',
                                                        borderBottom: cindex === 0 ? `2px solid #636363` : 'none',
                                                    }}>{cate}</span>
                                                })}
                                                
                                            </div>
                                            <div style={{
                                                fontSize: 14,
                                                color: `#ffffff`,
                                                marginTop: 12
                                            }}>
                                                {originData[item.key].slice(1).map((row, rowIndex) => {
                                                    return <Grid container>
                                                        <Grid item container direction="row" 
                                                            justify="space-between"
                                                            wrap="nowrap"
                                                            style={{
                                                                backgroundColor: rowIndex === 0 ? '#033FB3' : '#707070',
                                                                width: `${row[3]/10*100}%`,
                                                                borderTopRightRadius: 15,
                                                                borderBottomRightRadius: 15,
                                                                marginTop: 6,
                                                                padding: '4px'
                                                            }}>
                                                            <Grid item style={{
                                                                width: '70%',
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                wordBreak: 'break-all',
                                                                textOverflow: 'ellipsis',
                                                            }}>{`No.${row[0]} ${row[3] > 5 ? row[1] : ''}`}</Grid>
                                                            <Grid item style={{
                                                                paddingRight: 5
                                                            }}>{`${row[3]}`}</Grid>
                                                            
                                                        </Grid>
                                                        {row[3] <= 5 && <Grid item style={{
                                                            marginTop: 6,
                                                            padding: '4px',
                                                            width: '40%',
                                                            color: `#444444`,
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            wordBreak: 'break-all',
                                                            textOverflow: 'ellipsis',
                                                        }}>{row[1]}</Grid>}
                                                    </Grid>
                                                    
                                                })}
                                            </div>

                                        </div>
                                        
                                        {/* <Grid container direction="column" spacing={1} style={{
                                            fontSize: 14,
                                            color: `#ffffff`,
                                            marginTop: 12
                                        }}>
                                            {originData[item.key].slice(1).map((row, rowIndex) => {
                                                return <Grid item container direction="row" 
                                                    justify="space-between"
                                                    style={{
                                                        backgroundColor: rowIndex === 0 ? '#033FB3' : '#707070',
                                                        width: `${row[3]/10*100}%`,
                                                        borderTopRightRadius: 15,
                                                        borderBottomRightRadius: 15,
                                                        marginTop: 6
                                                    }}>
                                                    <Grid item>{`No.${row[0]} ${row[1]}`}</Grid>
                                                    <Grid item style={{
                                                        paddingRight: 5
                                                    }}>{`${row[3]}`}</Grid>
                                                </Grid>
                                            })}
                                        </Grid> */}
                                        
                                    </div>}
                                    {!originData[item.key] && <h3>{item.name}</h3>}
                                </>}
                                {item.key === 'content' && <>
                                    {originData[item.key] && <div>
                                        <div id="richText" dangerouslySetInnerHTML={{__html:html}}></div>
                                        </div>}
                                    {!originData[item.key] && <h3>{item.name}</h3>}
                                </>}
                            </Grid></Tooltip>
                        })}
                    </Grid>
                    
                </Paper>
            </Grid>
        </Grid>}
        
    </Container>
}

export default Overview