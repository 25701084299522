import React from 'react';

const Logo = (props) => {
  return (
    <div style={{width: 45, height: 45, backgroundColor: '#ffffff', borderRadius: 25}}>
      <img
        style={{width: 45, height: 45}}
        alt="Logo"
        src="/static/logo.png"
        {...props}
      />
    </div>
    
  );
};

export default Logo;
