import React, { useState, useContext, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Container,
  makeStyles,
  Grid,
  TextField,
  Button,
  InputLabel,
  FormHelperText,
  Tabs,
  Tab
} from '@material-ui/core'
import Page from 'src/components/Page'
import appContext from 'src/AppContext'
import { useParams } from "react-router-dom"
import UploadImage from 'src/utils/uploadImage'
import request from 'src/utils/request'
import LinkInput from 'src/components/LinkInput'
import ProductDetail from 'src/views/insure/product/detail'
import SearchSelect from 'src/utils/SearchSelect'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  Container: {
    backgroundColor: theme.palette.background.default,
    padding: 20
  },
  pageTitle: {
    paddingBottom: 20
  },
  form: {
    paddingTop: 20
  },
  tabs: {
    marginTop: 24,
    marginBottom: 36
  }
}))

// {
//     id: 1, // 产品ID，新增时不传
//     company_id: 1, // 保险公司ID，必须
//     title: '产品名称', // 必须
//     tags: [ // 标签
//         '标签1',
//         '标签2',
//     ],
//     category: '重疾险', // 分类，必须
//     theme: '{"bg_head":"http://xxx","bg_body":"http://xxx"}', // 主题样式
//     report_url: 'https://xxx', // 评测报告URL
//     deal_url: 'https://xxxx', // 交易地址，必须
// }

let formParams = [
    {
        key: 'title',
        text: '产品名称',
        type: 'text',
        defaultValue: ''
    },
    {
        key: 'category',
        text: '分类',
        type: 'text',
        defaultValue: ''
    },
    {
        key: 'company_id',
        text: '保险公司',
        defaultValue: '',
        options: []
    },
    {
        key: 'tags',
        text: '标签',
        type: 'text',
        defaultValue: '',
        placeholder: '标签之间请用,分隔'
    },
    {
        key: 'report_url',
        text: '评测报告跳转链接',
        type: 'link',
        defaultValue: ''
    },
    {
        key: 'deal_url',
        text: '交易跳转链接',
        type: 'link',
        defaultValue: ''
    },  
    {
        key: 'bg_head',
        text: '背景头图',
        type: 'image',
        defaultValue: '',
        width: 185,
        height: 100,
        prefix: 'insure/'
    },
    {
        key: 'bg_body',
        text: '背景图',
        type: 'image',
        defaultValue: '',
        width: 185,
        height: 100,
        prefix: 'insure/'
    }
]


const ProductEdit = () => {
    const classes = useStyles()
    const context = useContext(appContext)

    let { id } = useParams()
    let pageTitle = id !== undefined ? '编辑产品' : '添加产品'

    const [data, setData] = useState()
    const [companies, setCompanies] = useState([])
    const [kwForCompany, setKwForCompany] = useState()
    const [loading, setLoading] = useState(true)
    const [formList, setFormList] = useState(formParams)
    const [tab, setTab] = useState('tab1')

    
    useEffect(() => {
        if (id) {
            request({
                url: `/ajax/insurance/product/${id}`,
                method: 'get'
            }).then(res => {
                initFormList(res.data.product)
                setData(res.data.product)
                setLoading(false)
            })
        } else {
            initData()
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (kwForCompany) {
            request({
                url: `/ajax/insurance/companies?kw=${kwForCompany}`,
                method: 'get'
            }).then(res => {
                setCompanies(res.data.companies)
            })
        }
        
    }, [kwForCompany])

    useEffect(() => {
        if (window.location.hash) {
          setTab(window.location.hash.replace('#', ''))
        } else {
          setTab('tab1')
        }
      }, [window.location.hash])

    const initData = () => {
        let d = {}
        formList.forEach(item => {
            d[item.key] = item.defaultValue
        })
        setData(d)
    }

    const initFormList = (pdata) => {
        let theme = pdata.theme
        let copy = formList.concat()
        copy.forEach(fitem => {
            if (fitem.key === 'bg_head' || fitem.key === 'bg_body') {
                if (theme[fitem.key]) {
                    fitem.defaultValue = theme[fitem.key].replace(`https://pic.lmtest.cn/`, '')
                }
            } else if (fitem.key === 'tags') {
                fitem.defaultValue = pdata.tags.join(',')
            } else {
                fitem.defaultValue = pdata[fitem.key]
            }
        })
        setFormList(copy)
    }

    return <Page className={classes.root} title={pageTitle}>
        <Container maxWidth={false} className={classes.Container}>
            <h2 className={classes.pageTitle}>{pageTitle}</h2>
            <hr/>

            <Tabs 
                className={classes.tabs}
                value={tab} 
                onChange={(e, newValue) => {
                    setTab(newValue)
                    window.location.hash = newValue
                }}
                variant="fullWidth" 
                indicatorColor="primary">

                <Tab label="基础信息" value={'tab1'} />
                <Tab disabled={id === undefined} label="产品详情" value={'tab2'} />

            </Tabs>

            {!loading && data && tab === 'tab1' && <Formik
                initialValues={data}
                validationSchema={Yup.object().shape({
                    company_id: Yup.string().required('请选择保险公司'),
                    title: Yup.string().required('请填写产品名称'),
                    category: Yup.string().required('请填写分类'),
                    deal_url: Yup.string().required('请填写交易跳转链接')
                })}
                onSubmit={(values, { setSubmitting }) => {
                    let {company_id, title, tags, category, report_url, deal_url} = values
                    let theme = JSON.stringify({
                        bg_head: `https://pic.lmtest.cn/${values.bg_head}`,
                        bg_body: `https://pic.lmtest.cn/${values.bg_body}`
                    })

                    let data = {company_id, title, category, report_url, deal_url, theme}
                    if (id) {
                        data.id = id
                    }
                    if (typeof tags === 'string') {
                        tags = tags.split(',')
                    }

                    tags.forEach((t, i) => {
                        data[`tags[${i}]`] = t
                    })

                    request({
                        url: `/ajax/insurance/product/save`,
                        method: 'post',
                        data
                    }).then(res => {
                        context.showToast('保存成功')
                        setSubmitting(false)
                        if (!id) {
                            
                        }
                    })

                }}>
                {
                    ({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setFieldError,
                        touched,
                        values
                    }) => (
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" spacing={2}>
                                {formList.map(item => (
                                    <>{item.type === 'text' && <Grid container item xs={4}>
                                        <TextField
                                            fullWidth
                                            label={item.text} 
                                            name={item.key}
                                            value={values[item.key]}
                                            multiline={item.rows? true : false}
                                            rows={item.rows}
                                            placeholder={item.placeholder}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched[item.key] && errors[item.key])}
                                            helperText={touched[item.key] && errors[item.key]}/>
                                        </Grid>}
                                        {item.type === 'color' && <Grid container item xs={1}>
                                            <Grid item>
                                                <InputLabel shrink>{item.text}</InputLabel>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    name={item.key}
                                                    value={values[item.key]}
                                                    style={{backgroundColor: values[item.key]}}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched[item.key] && errors[item.key])}
                                                    helperText={touched[item.key] && errors[item.key]}/>  
                                            </Grid>
                                        </Grid>}
                                        {item.type === 'link' && <Grid container item xs={6} spacing={1} direction="column">
                                            <Grid item>
                                                <InputLabel shrink>{item.text}</InputLabel>
                                            </Grid>
                                            <Grid item>
                                                <LinkInput defaultValue={item.defaultValue} onChange={(v) => {
                                                    setFieldValue(item.key, v)
                                                    setFieldError(item.key, null)
                                                }}></LinkInput>
                                                {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                            </Grid>
                                        </Grid>}
                                        {item.key === 'company_id' && <Grid container item xs={2} direction="column">
                                            <SearchSelect value={values.company} label={item.text}
                                                list={companies}
                                                onKeyUp={(kw) => {
                                                    setKwForCompany(kw)
                                                }}
                                                onChange={(v) => {
                                                    if (!v) {
                                                        setFieldValue('company', null)
                                                        setFieldValue('company_id', '')
                                                    } else {
                                                        setFieldValue('company', v)
                                                        setFieldValue('company_id', v.id)
                                                    }
                                                }} />
                                            {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                        </Grid>}
                                        {(item.type === 'image' || item.type === 'audio') && <Grid container item xs={4} direction="column">
                                            <InputLabel shrink>{item.text}</InputLabel>
                                            <UploadImage 
                                                width={item.width} 
                                                height={item.height} 
                                                prefix={item.prefix}
                                                image={values[item.key]}
                                                text={`上传${item.text}`}
                                                type={item.type}
                                                onChange={(val) => {
                                                    setFieldValue(item.key, val)
                                                }}
                                                error={Boolean(touched[item.key] && errors[item.key])}></UploadImage>
                                        </Grid>}
                                    </>
                                    
                                ))}
                                <Grid item xs={2}>
                                    <Button color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained">保存</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }
            </Formik>}
            {!loading && data && tab === 'tab2' && <ProductDetail id={id} data={data} />}
        </Container>
    </Page>
}

export default ProductEdit